import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BaseComponent } from '@base-component';
import { deepEqual } from '@helpers';
import {
  CalculationType,
  ColumnDataType,
  ICalculationV2Map,
  IDataStream,
  IDataStreamColumn,
  IPandasFilterV2,
  IStorableWidgetV2,
  IThresholdsV2,
  ITrendFreq,
  IWidgetColorMapDumb,
  IWidgetColors,
  IWidgetConfig,
  SortingType,
  WidgetStep,
  WidgetType,
} from '@interfaces';
import {
  NbDialogRef,
  NbDialogService,
  NbPopoverDirective,
  NbSelectCompareFunction,
} from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { timezoneNames } from '@root/custom_scripts/config';
import {
  App,
  FilterVariable,
  Widget,
  WidgetEditor,
} from '@root/state/app.actions';
import { IAppStateModel } from '@root/state/app.model';
import { WidgetStateObject } from '@root/state/state-model-objects/widget.state-model';
import { EventQueueService, StateService } from '@services';
import { GridsterItem } from 'angular-gridster2';
import deepcopy from 'deepcopy';
import { getDefaultChartColors } from 'environments/default-settings/default-settings';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  pairwise,
  skip,
  startWith,
  take,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';
import { ColumnEditorComponent } from '../column-editor/column-editor.component';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { UserActionDialogComponent } from '@root/core-components/user-action-dialog/user-action-dialog.component';
import { HttpRequestService } from '@root/services/http-request.service';
import { v4 } from 'uuid';
import {
  conditionalValidator,
  convertToCalculationV2,
  convertToWidgetConfigOption,
  dailySnapshotTimeCutoffOptions,
  getAnchoredTimeSpans,
  getCalculationTypeDetails,
  getComputationConstants,
  getDefaultCalcFormValues,
  getDefaultChartSettings,
  getDefaultFormValues,
  getDefaultThresholds,
  getFormValidationErrors,
  getFormValuesFromStorableWidget,
  getRollingTimeSpans,
  getThresholdTooltips,
  getTimeIncrementValues,
  getWidgetTypeDetails,
  newDefaultAdvancedFilter,
  newDefaultSimpleFilter,
  timeSpanValueMap,
} from './helpers';
import { PercentageFilterDialogComponent } from './percentage-filter-dialog/percentage-filter-dialog.component';
import {
  ICalcFormValues,
  IDataStreamsByType,
  IWidgetFormValues,
} from './typing.interface';

@Component({
  selector: 'resplendent-widget-editor',
  templateUrl: './widget-editor.component.html',
  styleUrls: ['./widget-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('changeStep', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate(
          '0.15s 0.155s ease-in',
          keyframes([
            style({ opacity: 0, height: 0, offset: 0 }),
            style({ opacity: 0, height: '*', offset: 0.1 }),
            style({ opacity: 0.2, height: '*', offset: 0.6 }),
            style({ opacity: 1, height: '*', offset: 1 }),
          ]),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, height: '*', transform: 'translateX(0)' }),
        animate(
          '0.15s ease-out',
          style({ opacity: 0, height: '*', transform: 'translateX(-100%)' }),
        ),
      ]),
    ]),
    trigger('changeCalcTab', [
      transition(':enter', [
        style({ opacity: 0, width: 0 }),
        animate(
          '0.15s ease-in',
          keyframes([
            style({ opacity: 0, width: 0, offset: 0 }),
            style({ opacity: 0, width: 0, offset: 0.1 }),
            style({ opacity: 0, width: '*', offset: 0.2 }),
            style({ opacity: 0.2, width: '*', offset: 0.6 }),
            style({ opacity: 1, width: '*', offset: 1 }),
          ]),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, width: '*' }),
        animate('0.15s ease-out', style({ opacity: 0, width: 0 })),
      ]),
    ]),
  ],
})
export class WidgetEditorComponent extends BaseComponent implements OnInit {
  // Required
  // This will be set to new for the lifetime of the component if it is a new widget
  // The generated id is stored in the form
  @Input() public widgetId: string | 'new';
  @Input() public dashboardId: string;

  // Optional
  @Input() public gridsterItem: GridsterItem; // Position is optional due to free floating widgets

  // Helper variables
  isNewWidget = false;
  switchingCalculationTabSource$ = new BehaviorSubject<boolean>(false);
  switchingCalculationTab$ = this.switchingCalculationTabSource$.asObservable();
  widgetWasUpdated = false;
  changesMadeSinceLastPreview = false;
  showFilterEditor = false;
  overridePersistColumnOrder = false;

  otherWidgetsAndCalcs: {
    id: string;
    title: string;
    calculations: { id: string; name: string }[];
  }[] = [];

  step: WidgetStep = 'widgetType';
  form: UntypedFormGroup;
  formIsValid = false;
  widgetIsSavable = false;
  computations = getComputationConstants();
  anchoredTimeSpans = getAnchoredTimeSpans();
  rollingTimeSpans = getRollingTimeSpans();
  timeIncrementValues = getTimeIncrementValues();
  widgetTypeDetails = getWidgetTypeDetails();
  calculationsTypeDetails = getCalculationTypeDetails();
  primaryDefaultColors = getDefaultChartColors(0, 5);
  allDefaultColors = getDefaultChartColors();
  thresholdTooltips = getThresholdTooltips();
  widgetTimezones = timezoneNames();
  thresholdSnoozeTimes = [
    { value: 0, label: 'No Snooze' },
    { value: 60, label: '1 Minute' },
    { value: 300, label: '5 Minutes' },
    { value: 900, label: '15 Minutes' },
    { value: 1800, label: '30 Minutes' },
    { value: 3600, label: '1 Hour' },
    { value: 7200, label: '2 Hours' },
    { value: 14400, label: '4 Hours' },
    { value: 28800, label: '8 Hours' },
    { value: 43200, label: '12 Hours' },
    { value: 86400, label: '1 Day' },
  ];
  dailySnapshotTimeCutoffOptions = dailySnapshotTimeCutoffOptions;
  selectedThresholdsByCalculations = [];
  selectedThresholdsByCalculationsOptions = [];

  // Initialize observables
  editorState$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.widgetEditor,
  );
  dataStreamsState$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.dataStreamsState,
  );
  filterVariableState$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.filterVariableState,
  );
  dataStreamsByType$: Observable<IDataStreamsByType[]> =
    this.dataStreamsState$.pipe(
      takeUntil(this.isDestroyed$),
      filter((dataStreamsState) => !!dataStreamsState?.dataStreams),
      map((dataStreamsState) => {
        let dataStreamsByType: IDataStreamsByType[] = [];
        const dataStreams = dataStreamsState.dataStreams;
        if (dataStreams) {
          for (const dataStream of Object.values(dataStreams)) {
            // Check if a data stream type has already been initialized
            const dataStreamTypeIndex = dataStreamsByType.findIndex(
              (dataStreamType) => dataStreamType?.type === dataStream.type,
            );
            if (dataStreamTypeIndex === -1) {
              dataStreamsByType.push({
                type: dataStream.type,
                typeTitle:
                  dataStream.type === 'table' ? 'Datasets' : 'Data Modifiers',
                dataStreams: [dataStream],
              });
            } else {
              dataStreamsByType[dataStreamTypeIndex].dataStreams.push(
                dataStream,
              );
            }
          }
          return dataStreamsByType;
        }
      }),
    );

  widget$?: Observable<WidgetStateObject>;
  thresholdSoundboard$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.soundboard,
  );
  calculationPrevColumns: { [calcId: string]: string[] } = {};

  widgetColorMap: IWidgetColorMapDumb = {};

  previousWidgetType: WidgetType | null = null;
  previousFilter: { [key: number]: IPandasFilterV2 } = {};
  registeredFormControlChangeListeners = false;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private actions: Actions,
    private dialogRef: NbDialogRef<WidgetEditorComponent>,
    private dialogService: NbDialogService,
    private stateService: StateService,
    private router: Router,
    private httpRequest: HttpRequestService,
    private eventQueue: EventQueueService,
  ) {
    super();
  }

  async ngOnInit() {
    this.registerInitSubs();
    this.isNewWidget = this.widgetId === 'new';
    if (!this.isNewWidget) {
      this.step = 'config';
      await this.waitForWidget();
    }
    this.isNewWidget ? this.initForNewWidget() : this.initForExistingWidget();
    this.store.dispatch(new App.GetSoundboard());
    if (
      this.store.selectSnapshot(
        (state) => (state.app as IAppStateModel).appState.filterVariableState,
      ).filterVariables.length === 0
    ) {
      this.store.dispatch(new FilterVariable.GetAll());
    }

    this.listenForDataStreamColumns();
  }
  async waitForWidget() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    );
    if (!widget) {
      this.startLoading();
      await firstValueFrom(
        this.store
          .select(
            (state) =>
              (state.app as IAppStateModel).appState.widgets[this.widgetId],
          )
          .pipe(filter((widget) => !!widget)),
      );
      this.stopLoading();
    }
  }
  dropCalc(event: CdkDragDrop<string[]>, controlOptions): void {
    moveItemInArray(controlOptions, event.previousIndex, event.currentIndex);
    this.calculationOrderControl.setValue(
      controlOptions.map((calcForm) => calcForm.value.id),
    );
  }

  async listenForDataStreamColumns() {
    this.actions
      .pipe(
        ofActionSuccessful(App.FetchDataStreamColumnsSuccess),
        takeUntil(this.isDestroyed$),
        tap(() => {
          const dataStreamsState = this.store.selectSnapshot(
            (state) => (state.app as IAppStateModel).appState.dataStreamsState,
          );
          const dataStreams = dataStreamsState.dataStreams;
          this.calculationsFormArray.controls.forEach((calcForm, index) => {
            const dataStreamControl = this.calculationDataStreamControl(index);
            if (dataStreamControl.value?.id) {
              const dataStream = dataStreams[dataStreamControl.value.id];
              if (dataStream) {
                if (dataStream !== dataStreamControl.value) {
                  dataStreamControl.setValue(dataStream);
                }
                const columns = dataStream.columnNames;
                if (columns) {
                  const columnDetails = dataStream.columnDetails.map((col) => {
                    return {
                      ...col,
                      possibleValues: [],
                    };
                  });
                  this.calculationColumnsControl(index).setValue(columnDetails);
                  const currentColumns =
                    this.calculationSelectedColumnsControl(index).value;
                  const excludeMode =
                    this.calculationExcludeColumnsModeControl(index).value;
                  let columnsMatch = !!currentColumns;
                  if (currentColumns) {
                    for (let col of currentColumns) {
                      if (!columns.includes(col)) {
                        columnsMatch = false;
                        break;
                      }
                    }
                  }

                  if (
                    !currentColumns ||
                    (!currentColumns.length && !excludeMode) ||
                    !columnsMatch
                  ) {
                    this.calculationSelectedColumnsControl(index).setValue(
                      columns,
                      {
                        emitEvent: false,
                      },
                    );
                  }
                }
              }
            }
          });
        }),
      )
      .subscribe();
  }

  /** Initialize component to create a new widget */
  async initForNewWidget() {
    const userTimezone = this.stateService.sessionVariables.user?.timezone;
    const defaultValues = getDefaultFormValues();
    if (userTimezone) defaultValues.timezone = userTimezone;
    this.widgetId = defaultValues.id;
    // Gridster item is an input from the dash, we can't default it
    if (!this.gridsterItem)
      throw new Error('Gridster item is required for new widgets');
    defaultValues.gridsterItem = this.gridsterItem;
    this.initializeForm(defaultValues, true);
  }

  /** Initialize component to edit an existing widget */
  async initForExistingWidget() {
    this.startLoading();
    this.step = 'config';

    this.actions
      .pipe(
        ofActionSuccessful(App.FetchDataStreamColumnsSuccess),
        take(1),
        tap(() => {
          const widget = this.store.selectSnapshot(
            (state) =>
              (state.app as IAppStateModel).appState.widgets[this.widgetId],
          );
          this.previousWidgetType = widget.widgetType;
          const dataStreamsState = this.store.selectSnapshot(
            (state) => (state.app as IAppStateModel).appState.dataStreamsState,
          );
          const dataStreams = dataStreamsState.dataStreams;
          const formValues = getFormValuesFromStorableWidget(
            widget,
            dataStreams,
          );
          this.initializeForm(formValues);
          this.dashboardId = widget.dashboardV2Id;
          this.fixMatrixSortCol();
          // Fetch all of the other data streams so we can populate the data stream dropdowns
          this.store.dispatch(new App.FetchDataStreams(false));
        }),
      )
      .subscribe();

    // Fetch widget and then fetch data streams with all the columns
    this.store.dispatch(
      new App.FetchDataStreams(true, [this.widgetId], false, true),
    );
  }

  /** Initialize the component main form group with values from the argument */
  async initializeForm(values: IWidgetFormValues, newWidget = false) {
    const calcFormGroups = values.calculations.map((calc) =>
      this.getCalculationFormGroup(calc),
    );
    calcFormGroups.forEach((calcForm, i) => {
      const calcFormValues = calcForm.getRawValue();
      this.calculationPrevColumns[calcFormValues.id] =
        calcFormValues.selectedColumns;
    });
    const thresholdFormGroups = this.getThresholdsFormGroup(
      values.thresholds,
      '',
    );
    let thresholdsByCalculationFormGroups = {};
    for (const calcId in values.thresholdsByCalculation) {
      thresholdsByCalculationFormGroups[calcId] = this.getThresholdsFormGroup(
        values.thresholdsByCalculation[calcId],
        calcId,
      );
    }
    this.selectedThresholdsByCalculations = [
      ...Object.keys(values?.thresholdsByCalculation ?? {}),
    ];
    thresholdsByCalculationFormGroups = this.fb.group(
      thresholdsByCalculationFormGroups,
    );
    this.form = this.fb.group({
      widgetType: [
        values.widgetType,
        conditionalValidator(
          () => this.isNewWidget,
          Validators.required,
          'Widget type is required',
        ),
      ],
      calculationsType: [
        values.calculationsType,
        conditionalValidator(
          () => this.isDataBasedWidget && this.isNewWidget,
          Validators.required,
          'Widget data type is required',
        ),
      ],
      // Since we can have multiple data streams, we need to use a FormArray
      // Additions happen later since we need to track every added calculation
      calculations: this.fb.array([]),
      title: [values.title, Validators.required],
      description: [values.description],
      thresholds: thresholdFormGroups,
      thresholdsByCalculation: thresholdsByCalculationFormGroups,
      selectedCalcIndex: [0],
      colorType: [values.colorType, Validators.required], // colors | thresholds
      imageUrl: [
        values.imageUrl,
        conditionalValidator(
          () => this.widgetTypeIs('image'),
          Validators.required,
          'Image URL is required',
        ),
      ],
      iFrameUrl: [
        values.iFrameUrl,
        conditionalValidator(
          () => this.widgetTypeIs('iFrame'),
          Validators.required,
          'IFrame URL is required',
        ),
      ],
      staticString: [
        values.staticString,
        conditionalValidator(
          () => this.widgetTypeIs('staticString'),
          Validators.required,
          'Static value is required',
        ),
      ],
      xAxis: [values.xAxis],
      yAxis: [values.yAxis],
      yMin: [values.yMin],
      yMax: [values.yMax],
      xLabel: [values.xLabel],
      yLabel: [values.yLabel],
      fontSize: [values.fontSize],
      legend: [values.legend],
      legendPosition: [values.legendPosition],
      overlayCurrentNumber: [values.overlayCurrentNumber],
      dataLabels: [values.dataLabels],
      percentSuffix: [values.percentSuffix],
      dollarPrefix: [values.dollarPrefix],
      abbreviateNumbers: [values.abbreviateNumbers],
      showHistoricalData: [values.showHistoricalData],
      stringValueSize: [values.stringValueSize],
      grouping: [values.grouping],
      showArea: [values.showArea],
      showThresholdMarkers: [values.showThresholdMarkers],
      comparePoints: [values.comparePoints],
      comparisonType: [values.comparisonType],
      comparisonCustomValue: [values.comparisonCustomValue],
      comparisonMethod: [values.comparisonMethod],
      comparisonOnlyShowInLabel: [values.comparisonOnlyShowInLabel],
      sorting: [values.sorting],
      groupBySorting: [values.groupBySorting],
      valueLimit: [values.valueLimit],
      barGap: [values.barGap],
      timeIncrement: [values.timeIncrement],
      dailySnapshotTimeCutoff: [values.dailySnapshotTimeCutoff],
      timezone: [values.timezone],
      rounding: [values.rounding],
      showWidgetTitle: [values.showWidgetTitle],
      modalDataSortColumn: [values.modalDataSortColumn],
      modalDataSortAscending: [values.modalDataSortAscending],
      modalDataLimit: [values.modalDataLimit],
      dynamicStringColumn: [values.dynamicStringColumn],
      cumulativeXAxis: [values.cumulativeXAxis],
      xAxisBaseline: [values.xAxisBaseline],
      xAxisLabelRotation: [values.xAxisLabelRotation],
      pieRadius: [values.pieRadius],
      pieInnerRadius: [values.pieInnerRadius],
      maxGaugeValue: [values.maxGaugeValue],
      minGaugeValue: [values.minGaugeValue],
      yAxisLogScale: [values.yAxisLogScale],
      logScaleBase: [values.logScaleBase],
      calculationOrder: [values.calculationOrder],
      hideZeroLabels: [values.hideZeroLabels],
      showStackTotal: [values.showStackTotal],
      pinnedColumns: [values.pinnedColumns],
      matrixMissingValue: [values.matrixMissingValue],
      matrixIndexLabel: [values.matrixIndexLabel],
      drilldownDateFormat: [values.drilldownDateFormat],
      useCalculationThresholds: [values.useCalculationThresholds],
      transposeTableData: [values.transposeTableData],
      transposeIncludeHeader: [values.transposeIncludeHeader],
      transposeHeaderColumnName: [values.transposeHeaderColumnName],
      transposeColumn: [values.transposeColumn],
      transposePinHeaderColumn: [values.transposePinHeaderColumn],
      pivotTableData: [values.pivotTableData],
      pivotColumns: [values.pivotColumns],
      pivotValues: [values.pivotValues],
      pivotIndex: [values.pivotIndex],
      pivotAggFunc: [values.pivotAggFunc],
      pivotPinIndexColumns: [values.pivotPinIndexColumns],
      columnsWithPercentSuffix: [values.columnsWithPercentSuffix],
      columnsWithDollarPrefix: [values.columnsWithDollarPrefix],
      fillEmptyPoints: [values.fillEmptyPoints],
      horizontalChart: [values.horizontalChart],
      compareWithPreviousPeriod: [values.compareWithPreviousPeriod],
      useXAxisInDashboardDateFilter: [values.useXAxisInDashboardDateFilter],
      fitGridToWidgetWidth: [values.fitGridToWidgetWidth],
      // Values that are not part of the form, but are needed for the widget
      id: [values.id],
      groupId: [values.groupId],
      configId: [values.configId],
      gridsterItem: [values.gridsterItem],
    });
    calcFormGroups.forEach((calcForm, index) => {
      this.addCalculation(calcForm);
    });
    // if (newWidget && this.isDataBasedWidget) {
    //   // If it's a new widget, add a default calculation
    //   await this.addCalculation();
    // }

    this.formIsValid = this.form.valid;

    if (!this.isDataBasedWidget) {
      this.stopLoading();
      // if (!this.registeredFormControlChangeListeners) {
      this.registerIndividualFormControlChangeListeners();
      // }
      return;
    }

    this.stopLoading();
    this.setDisabledOnMissingDataStream();
    // if (!this.registeredFormControlChangeListeners) {
    this.registerIndividualFormControlChangeListeners();
    // }
  }
  /** Create a calculation form group with default values */
  getNewCalculationFormGroup() {
    const defaultValues = getDefaultCalcFormValues();
    return this.getCalculationFormGroup(defaultValues);
  }
  /** Create a calculation form group with init values from the argument */
  getCalculationFormGroup(values: ICalcFormValues) {
    return this.fb.group({
      dataStream: [
        values.dataStream,
        conditionalValidator(
          () => this.isDataBasedWidget,
          // The data stream is required and can't be a string. It must be an object with an id property.
          Validators.compose([
            Validators.required,
            (control) =>
              typeof control.value === 'string' ? { required: true } : null,
          ]),
          'Data stream is required',
        ),
      ],
      name: [values.name],
      xAxis: [
        values.xAxis,
        conditionalValidator(
          () => this.isStandard && this.isLine,
          Validators.required,
          'You must specify an X axis',
        ),
      ],
      xAxis2: [values.xAxis2],
      groupBy: [values.groupBy],
      simpleFilter: [values.simpleFilter],
      pandasFilter: [
        values.pandasFilter,
        conditionalValidator(
          () => values.filterType === 'pandas',
          Validators.required,
          'Filter is required',
        ),
      ],
      filterType: [values.filterType, Validators.required],
      percentFilter: [values.percentFilter],
      computation: [
        values.computation,
        conditionalValidator(
          () => this.isDataBasedWidget,
          Validators.required,
          'You must select the calculation type',
        ),
      ],
      computationColumn: [
        values.computationColumn,
        conditionalValidator(
          () =>
            this.isDataBasedWidget &&
            values.computation?.requiresComputationColumn &&
            !values.useCustomCalculationColumn,
          Validators.required,
          'You must select the column to calculate',
        ),
      ],
      timeWindow: [
        timeSpanValueMap.find(
          (timeWindow) => timeWindow.value === values.timeWindow?.value,
        ),
        conditionalValidator(
          () => this.isSnapshot,
          Validators.required,
          'You must select the time window',
        ),
      ],
      delimiter: [values.delimiter],
      delimiter2: [values.delimiter2],
      selectedColumns: [values.selectedColumns],
      useDefaultColor: [values.useDefaultColor],
      color: [values.color],
      // Not part of the form, but used to store values
      id: [values.id],
      columns: [values.columns],
      excludeColumnsMode: [values.excludeColumnsMode],
      filteredColumns: [values.filteredColumns],
      columnOptions: [values.columnOptions],
      columnAliasMap: [values.columnAliasMap],
      useCustomCalculationColumn: [values.useCustomCalculationColumn],
      customCalculationColumn: [values.customCalculationColumn],
      dateFilterColumn: [values.dateFilterColumn],
    });
  }
  /** Create a threshold form group with init values from the argument */
  getThresholdsFormGroup(thresholds: IThresholdsV2, calcId?: string) {
    const customThresholds = thresholds.custom.map((t) =>
      this.fb.group({
        color: [t.color],
        value: [t.value],
        soundId: [t.soundId],
        comparator: [t.comparator],
        repeatSound: [t.repeatSound],
        repeatInterval: [t.repeatInterval],
      }),
    );
    if (customThresholds.length === 0)
      customThresholds.push(this.getNewCustomThresholdGroup(calcId));
    // Sort the custom thresholds by value in descending order
    customThresholds.sort(
      (a, b) => b.get('value').value - a.get('value').value,
    );
    return this.fb.group({
      bottom: [thresholds.bottom],
      custom: this.fb.array(customThresholds),
      snooze: [thresholds.snooze],
      allowMute: [thresholds.allowMute],
    });
  }
  /** Create a default custom threshold form group */
  getNewCustomThresholdGroup(calcId?: string) {
    let color = this.allDefaultColors[2];
    const calcLen =
      this.thresholdsByCalculationControl?.getRawValue()?.[calcId]?.custom
        ?.length;
    if (calcId && calcLen) {
      color = this.allDefaultColors[calcLen];
    } else {
      color = !!this.form
        ? this.allDefaultColors[this.customThresholdsFormArray.controls.length]
        : this.allDefaultColors[2];
    }

    return this.fb.group({
      color: [color],
      value: [5],
      soundId: [null],
      comparator: ['lte'],
      repeatSound: [false],
      repeatInterval: [60],
    });
  }
  newCalculationThresholdGroup(calcId: string) {
    if (calcId in this.thresholdsByCalculationControl.controls) {
      this.thresholdsByCalculationControl.removeControl(calcId);
    } else {
      this.thresholdsByCalculationControl.addControl(
        calcId,
        this.getThresholdsFormGroup(getDefaultThresholds(), calcId),
      );
    }
    this.selectedThresholdsByCalculations = [
      ...Object.keys(this.thresholdsByCalculationControl.controls),
    ];
  }
  // Controls accessed by key (non indexed) getters
  get widgetTypeControl() {
    return this.form?.get('widgetType') as UntypedFormControl;
  }
  get calculationsTypeControl() {
    return this.form.get('calculationsType') as UntypedFormControl;
  }
  get calculationsFormArray() {
    return this.form.get('calculations') as UntypedFormArray;
  }
  get titleControl() {
    return this.form.get('title') as UntypedFormControl;
  }
  get descriptionControl() {
    return this.form.get('description') as FormControl<string>;
  }
  get thresholdsControl() {
    return this.form.get('thresholds') as UntypedFormGroup;
  }
  get thresholdsByCalculationControl() {
    return this.form?.get('thresholdsByCalculation') as UntypedFormGroup;
  }
  calcThresholdsControl(calcId: string) {
    return this.thresholdsByCalculationControl?.controls?.[
      calcId
    ] as UntypedFormGroup;
  }
  calcThresholdsCustomControl(calcId: string) {
    return this.calcThresholdsControl(calcId)?.get(
      'custom',
    ) as UntypedFormArray;
  }
  calcBottomThresholdControl(calcId: string) {
    return this.calcThresholdsControl(calcId)?.get(
      'bottom',
    ) as UntypedFormControl;
  }
  calcCustomThresholdsFormArray(calcId: string) {
    return this.calcThresholdsControl(calcId)?.get(
      'custom',
    ) as UntypedFormArray;
  }
  get selectedCalcIndexControl() {
    return this.form.get('selectedCalcIndex') as UntypedFormControl;
  }
  get colorTypeControl() {
    return this.form.get('colorType') as UntypedFormControl;
  }
  get topThresholdControl() {
    return this.thresholdsControl.get('top') as UntypedFormControl;
  }
  get bottomThresholdControl() {
    return this.thresholdsControl.get('bottom') as UntypedFormControl;
  }
  get customThresholdsFormArray() {
    return this.thresholdsControl.controls?.custom as UntypedFormArray;
  }
  get thresholdSnoozeTimeControl() {
    return this.thresholdsControl.get('snooze') as UntypedFormControl;
  }
  get thresholdAllowMuteControl() {
    return this.thresholdsControl.get('allowMute') as UntypedFormControl;
  }
  get imageUrlControl() {
    return this.form.get('imageUrl') as UntypedFormControl;
  }
  get iFrameUrlControl() {
    return this.form.get('iFrameUrl') as UntypedFormControl;
  }
  get staticStringControl() {
    return this.form.get('staticString') as UntypedFormControl;
  }
  get xAxisControl() {
    return this.form.get('xAxis') as UntypedFormControl;
  }
  get yAxisControl() {
    return this.form.get('yAxis') as UntypedFormControl;
  }
  get yMinControl() {
    return this.form.get('yMin') as UntypedFormControl;
  }
  get yMaxControl() {
    return this.form.get('yMax') as UntypedFormControl;
  }
  get xLabelControl() {
    return this.form.get('xLabel') as FormControl<string | null>;
  }
  get yLabelControl() {
    return this.form.get('yLabel') as FormControl<string | null>;
  }
  get fontSizeControl() {
    return this.form.get('fontSize') as UntypedFormControl;
  }
  get legendControl() {
    return this.form.get('legend') as UntypedFormControl;
  }
  get legendPositionControl() {
    return this.form.get('legendPosition') as FormControl<
      'top' | 'bottom' | 'left' | 'right'
    >;
  }
  get overlayCurrentNumberControl() {
    return this.form.get('overlayCurrentNumber') as UntypedFormControl;
  }
  get dataLabelsControl() {
    return this.form.get('dataLabels') as UntypedFormControl;
  }
  get percentSuffixControl() {
    return this.form.get('percentSuffix') as UntypedFormControl;
  }
  get dollarPrefixControl() {
    return this.form.get('dollarPrefix') as UntypedFormControl;
  }
  get abbreviateNumbersControl() {
    return this.form.get('abbreviateNumbers') as UntypedFormControl;
  }
  get showHistoricalDataControl() {
    return this.form.get('showHistoricalData') as UntypedFormControl;
  }
  get roundingControl() {
    return this.form.get('rounding') as UntypedFormControl;
  }
  get showWidgetTitleControl() {
    return this.form.get('showWidgetTitle') as UntypedFormControl;
  }
  get stringValueSizeControl() {
    return this.form.get('stringValueSize') as UntypedFormControl;
  }
  get groupingControl() {
    return this.form.get('grouping') as UntypedFormControl;
  }
  get showAreaControl() {
    return this.form.get('showArea') as UntypedFormControl;
  }
  get showThresholdMarkersControl() {
    return this.form.get('showThresholdMarkers') as UntypedFormControl;
  }
  get comparePointsControl() {
    return this.form.get('comparePoints') as FormControl<boolean>;
  }
  get comparisonTypeControl() {
    return this.form.get('comparisonType') as FormControl<
      null | 'previous' | 'custom'
    >;
  }
  get comparisonCustomValueControl() {
    return this.form.get('comparisonCustomValue') as FormControl<null | number>;
  }
  get comparisonMethodControl() {
    return this.form.get('comparisonMethod') as FormControl<
      null | 'percent' | 'difference'
    >;
  }
  get comparisonOnlyShowInLabelControl() {
    return this.form.get('comparisonOnlyShowInLabel') as FormControl<boolean>;
  }
  get sortingControl() {
    return this.form.get('sorting') as UntypedFormControl;
  }
  get groupBySortingControl() {
    return this.form.get('groupBySorting') as FormControl<SortingType>;
  }
  get valueLimitControl() {
    return this.form.get('valueLimit') as UntypedFormControl;
  }
  get matrixMissingValueControl() {
    return this.form.get('matrixMissingValue') as FormControl<string>;
  }
  get matrixIndexLabelControl() {
    return this.form.get('matrixIndexLabel') as FormControl<string>;
  }
  get drilldownDateFormatControl() {
    return this.form.get('drilldownDateFormat') as FormControl<string>;
  }
  get useCalculationThresholdsControl() {
    return this.form.get('useCalculationThresholds') as FormControl<boolean>;
  }
  get transposeTableDataControl() {
    return this.form.get('transposeTableData') as FormControl<boolean>;
  }
  get transposeIncludeHeaderControl() {
    return this.form.get('transposeIncludeHeader') as FormControl<boolean>;
  }
  get transposeHeaderColumnNameControl() {
    return this.form.get('transposeHeaderColumnName') as FormControl<string>;
  }
  get transposeColumnControl() {
    return this.form.get('transposeColumn') as FormControl<string>;
  }
  get transposePinHeaderColumnControl() {
    return this.form.get('transposePinHeaderColumn') as FormControl<boolean>;
  }
  get pivotTableDataControl() {
    return this.form.get('pivotTableData') as FormControl<boolean>;
  }
  get pivotColumnsControl() {
    return this.form.get('pivotColumns') as FormControl<string[]>;
  }
  get pivotValuesControl() {
    return this.form.get('pivotValues') as FormControl<string[]>;
  }
  get pivotIndexControl() {
    return this.form.get('pivotIndex') as FormControl<string>;
  }
  get pivotAggFuncControl() {
    return this.form.get('pivotAggFunc') as FormControl<string>;
  }
  get pivotPinIndexColumnsControl() {
    return this.form.get('pivotPinIndexColumns') as FormControl<boolean>;
  }
  get columnsWithPercentSuffixControl() {
    return this.form.get('columnsWithPercentSuffix') as FormControl<string[]>;
  }
  get columnsWithDollarPrefixControl() {
    return this.form.get('columnsWithDollarPrefix') as FormControl<string[]>;
  }
  get barGapControl() {
    return this.form.get('barGap') as FormControl<number>;
  }
  get timeIncrementControl() {
    return this.form.get('timeIncrement') as UntypedFormControl;
  }
  get dailySnapshotTimeCutoffControl() {
    return this.form.get('dailySnapshotTimeCutoff') as UntypedFormControl;
  }
  get timezoneControl() {
    return this.form.get('timezone') as UntypedFormControl;
  }
  get modalDataSortColumnControl() {
    return this.form.get('modalDataSortColumn') as UntypedFormControl;
  }
  get modalDataSortAscendingControl() {
    return this.form.get('modalDataSortAscending') as UntypedFormControl;
  }
  get modalDataLimitControl() {
    return this.form.get('modalDataLimit') as UntypedFormControl;
  }
  get dynamicStringColumnControl() {
    return this.form.get('dynamicStringColumn') as UntypedFormControl;
  }
  get cumulativeXAxisControl() {
    return this.form.get('cumulativeXAxis') as UntypedFormControl;
  }
  get xAxisBaselineControl() {
    return this.form.get('xAxisBaseline') as UntypedFormControl;
  }
  get xAxisLabelRotationControl() {
    return this.form.get('xAxisLabelRotation') as UntypedFormControl;
  }
  get pieRadiusControl() {
    return this.form.get('pieRadius') as UntypedFormControl;
  }
  get pieInnerRadiusControl() {
    return this.form.get('pieInnerRadius') as UntypedFormControl;
  }
  get maxGaugeValueControl() {
    return this.form.get('maxGaugeValue') as UntypedFormControl;
  }
  get minGaugeValueControl() {
    return this.form.get('minGaugeValue') as UntypedFormControl;
  }
  get yAxisLogScaleControl() {
    return this.form.get('yAxisLogScale') as UntypedFormControl;
  }
  get logScaleBaseControl() {
    return this.form.get('logScaleBase') as UntypedFormControl;
  }
  get calculationOrderControl() {
    return this.form.get('calculationOrder') as UntypedFormControl;
  }
  get hideZeroLabelsControl() {
    return this.form.get('hideZeroLabels') as FormControl<boolean>;
  }
  get showStackTotalControl() {
    return this.form.get('showStackTotal') as FormControl<boolean>;
  }
  get pinnedColumnsControl() {
    return this.form.get('pinnedColumns') as FormControl<string[]>;
  }
  get fillEmptyPointsControl() {
    return this.form.get('fillEmptyPoints') as FormControl<boolean>;
  }
  get horizontalChartControl() {
    return this.form.get('horizontalChart') as FormControl<boolean>;
  }
  get fitGridToWidgetWidthControl() {
    return this.form.get('fitGridToWidgetWidth') as FormControl<boolean>;
  }
  get compareWithPreviousPeriodControl() {
    return this.form.get('compareWithPreviousPeriod') as FormControl<string>;
  }
  get useXAxisInDashboardDateFilterControl() {
    return this.form.get(
      'useXAxisInDashboardDateFilter',
    ) as FormControl<boolean>;
  }

  // General getters
  get isSnapshot() {
    return !!this.form && this.calculationsTypeControl.value === 'snapshot';
  }
  get isStandard() {
    return !!this.form && this.calculationsTypeControl.value === 'standard';
  }
  get isDataBasedWidget() {
    return (
      !!this.form &&
      this.widgetTypeControl.value !== 'iFrame' &&
      this.widgetTypeControl.value !== 'image' &&
      this.widgetTypeControl.value !== 'staticString'
    );
  }
  get thresholdsAllowed() {
    return (
      this.widgetTypeControl.value === 'line' ||
      this.widgetTypeControl.value === 'bar' ||
      this.widgetTypeControl.value === 'number' ||
      this.widgetTypeControl.value === 'matrix'
    );
  }
  get formLoaded() {
    return !!this.form;
  }
  get topThresholdAllowed() {
    return this.isCartesian || this.widgetTypeIs('number');
  }
  get isLine() {
    return this.widgetTypeControl.value === 'line';
  }
  get isCartesian() {
    return (
      this.widgetTypeControl.value === 'line' ||
      this.widgetTypeControl.value === 'bar'
    );
  }
  get isRadial() {
    return (
      this.widgetTypeControl.value === 'pie' ||
      this.widgetTypeControl.value === 'doughnut'
    );
  }
  get isChart() {
    return (
      this.isCartesian ||
      this.isRadial ||
      this.widgetTypeIs('gauge') ||
      this.widgetTypeIs('funnel')
    );
  }
  get containsTextValue() {
    return (
      this.widgetTypeIs('staticString') ||
      this.widgetTypeIs('number') ||
      this.widgetTypeIs('matrix') ||
      this.isChart
    );
  }

  get isTextBased() {
    return (
      this.widgetTypeIs('staticString') ||
      this.widgetTypeIs('number') ||
      this.widgetTypeIs('dynamicString')
    );
  }

  get supportsColor() {
    return (
      this.widgetTypeIs('line') ||
      this.widgetTypeIs('bar') ||
      this.widgetTypeIs('pie') ||
      this.widgetTypeIs('doughnut') ||
      this.widgetTypeIs('gauge') ||
      this.widgetTypeIs('number') ||
      this.widgetTypeIs('matrix')
    );
  }

  get hasComputationColumn() {
    return (
      this.isDataBasedWidget &&
      this.widgetTypeControl.value !== 'table' &&
      this.widgetTypeControl.value !== 'dynamicString'
    );
  }
  get isTimeBased() {
    return (
      this.isDataBasedWidget &&
      (this.showHistoricalDataControl.value || this.isCartesian)
    );
  }
  get matrixSortOpts() {
    return [
      '____name',
      ...this.getFormState().calculations.map((calc) => calc.id),
    ];
  }
  getCalcColumnsString(index: number) {
    const excludeColumnsMode =
      this.calculationExcludeColumnsModeControl(index).value;
    const selectedColumns = this.calculationSelectedColumnsControl(index).value;
    const allColumns = this.calculationColumnsControl(index).value;
    const reverseAliasMap = allColumns.reduce((acc, col) => {
      acc[col.alias || col.name] = col.name;
      return acc;
    }, {});
    let colList = [];

    for (const col of allColumns) {
      if (excludeColumnsMode && !selectedColumns?.includes(col.name)) {
        colList.push(col.alias || col.name);
      } else if (!excludeColumnsMode && selectedColumns?.includes(col.name)) {
        colList.push(col.alias || col.name);
      }
    }
    if (!excludeColumnsMode) {
      colList.sort((a, b) => {
        return (
          selectedColumns.indexOf(reverseAliasMap[a]) -
          selectedColumns.indexOf(reverseAliasMap[b])
        );
      });
    }

    return colList.join(', ');
  }

  // Indexed getters
  getCustomThresholdColorControl(index: number) {
    return this.customThresholdsFormArray.controls[index].get(
      'color',
    ) as UntypedFormControl;
  }
  getCustomThresholdValueControl(index: number) {
    return this.customThresholdsFormArray.controls?.[index]?.get(
      'value',
    ) as UntypedFormControl;
  }
  getCustomThresholdSoundIdControl(index: number, calcId?: string) {
    if (calcId) {
      return this.calcThresholdsCustomControl(calcId)?.controls?.[index]?.get(
        'soundId',
      ) as UntypedFormControl;
    }
    return this.customThresholdsFormArray.controls[index].get(
      'soundId',
    ) as UntypedFormControl;
  }
  getCustomThresholdComparatorControl(index: number, calcId?: string) {
    if (calcId) {
      return this.calcThresholdsCustomControl(calcId)?.controls?.[index]?.get(
        'comparator',
      ) as UntypedFormControl;
    }
    return this.customThresholdsFormArray.controls[index].get(
      'comparator',
    ) as UntypedFormControl;
  }
  getCustomThresholdRepeatSoundControl(index: number, calcId?: string) {
    if (calcId) {
      return this.calcThresholdsCustomControl(calcId)?.controls?.[index]?.get(
        'repeatSound',
      ) as UntypedFormControl;
    }
    return this.customThresholdsFormArray.controls[index].get(
      'repeatSound',
    ) as UntypedFormControl;
  }
  getCustomThresholdRepeatIntervalControl(index: number, calcId?: string) {
    if (calcId) {
      return this.calcThresholdsCustomControl(calcId)?.controls?.[index]?.get(
        'repeatInterval',
      ) as UntypedFormControl;
    }
    return this.customThresholdsFormArray.controls[index].get(
      'repeatInterval',
    ) as UntypedFormControl;
  }
  // indexed calc threshold getters
  getCustomCalcThresholdColorControl(calcId: string, index: number) {
    return this.calcThresholdsCustomControl(calcId)?.controls?.[index]?.get(
      'color',
    ) as UntypedFormControl;
  }
  getCustomCalcThresholdValueControl(calcId: string, index: number) {
    return this.calcThresholdsCustomControl(calcId)?.controls?.[index]?.get(
      'value',
    ) as UntypedFormControl;
  }
  getCustomCalcThresholdComparatorControl(calcId: string, index: number) {
    return this.calcThresholdsCustomControl(calcId)?.controls?.[index]?.get(
      'comparator',
    ) as UntypedFormControl;
  }
  getCustomCalcThresholdRepeatSoundControl(calcId: string, index: number) {
    return this.calcThresholdsCustomControl(calcId)?.controls?.[index]?.get(
      'repeatSound',
    ) as UntypedFormControl;
  }
  getCustomCalcThresholdRepeatIntervalControl(calcId: string, index: number) {
    return this.calcThresholdsCustomControl(calcId)?.controls?.[index]?.get(
      'repeatInterval',
    ) as UntypedFormControl;
  }
  calculationFormGroup(index: number) {
    return this.calculationsFormArray.controls[index] as UntypedFormGroup;
  }
  calculationDataStreamControl(index: number) {
    return this.calculationFormGroup(index).get(
      'dataStream',
    ) as UntypedFormControl;
  }
  calculationNameControl(index: number) {
    return this.calculationFormGroup(index).get('name') as UntypedFormControl;
  }
  calculationXAxisControl(index: number) {
    return this.calculationFormGroup(index).get('xAxis') as UntypedFormControl;
  }
  calculationXAxis2Control(index: number) {
    return this.calculationFormGroup(index).get('xAxis2') as UntypedFormControl;
  }
  // calculationGroupingColumnsControl(index: number) {
  //   return this.calculationFormGroup(index).get('groupingColumns') as FormControl;
  // }
  calculationGroupByControl(index: number) {
    return this.calculationFormGroup(index).get(
      'groupBy',
    ) as UntypedFormControl;
  }
  calculationSimpleFilterControl(index: number) {
    return this.calculationFormGroup(index).get(
      'simpleFilter',
    ) as UntypedFormControl;
  }
  calculationPandasFilterControl(index: number) {
    return this.calculationFormGroup(index).get(
      'pandasFilter',
    ) as FormControl<IPandasFilterV2>;
  }
  calculationFilterTypeControl(index: number) {
    return this.calculationFormGroup(index).get(
      'filterType',
    ) as UntypedFormControl;
  }
  calculationComputationControl(index: number) {
    return this.calculationFormGroup(index).get(
      'computation',
    ) as UntypedFormControl;
  }
  calculationComputationColumnControl(index: number) {
    return this.calculationFormGroup(index).get(
      'computationColumn',
    ) as UntypedFormControl;
  }
  calculationUseCustomCalculationColumnControl(index: number) {
    return this.calculationFormGroup(index).get(
      'useCustomCalculationColumn',
    ) as UntypedFormControl;
  }
  calculationCustomCalculationColumnControl(index: number) {
    return this.calculationFormGroup(index).get(
      'customCalculationColumn',
    ) as UntypedFormControl;
  }
  calculationDateFilterColumnControl(index: number) {
    return this.calculationFormGroup(index).get(
      'dateFilterColumn',
    ) as UntypedFormControl;
  }
  calculationPercentageFilterControl(index: number) {
    return this.calculationFormGroup(index).get(
      'percentFilter',
    ) as UntypedFormControl;
  }
  showCalculationComputationColumn(index: number) {
    return this.calculationComputationControl(index).value
      ?.requiresComputationColumn;
  }
  isCustomCalculation(index: number) {
    return this.calculationComputationControl(index).value?.val === 'custom';
  }
  isPercentageCalculation(index: number) {
    return (
      this.calculationComputationControl(index).value?.val === 'percentage'
    );
  }
  isPercentCalculationSet(index: number) {
    return this.calculationPercentageFilterControl(index).value !== null;
  }
  calculationTimeWindowControl(index: number) {
    return this.calculationFormGroup(index).get(
      'timeWindow',
    ) as UntypedFormControl;
  }
  calculationDelimiterControl(index: number) {
    return this.calculationFormGroup(index).get(
      'delimiter',
    ) as UntypedFormControl;
  }
  calculationDelimiter2Control(index: number) {
    return this.calculationFormGroup(index).get(
      'delimiter2',
    ) as UntypedFormControl;
  }
  calculationSelectedColumnsControl(index: number) {
    return this.calculationFormGroup(index).get(
      'selectedColumns',
    ) as UntypedFormControl;
  }
  calculationUseDefaultColorControl(index: number) {
    return this.calculationFormGroup(index).get(
      'useDefaultColor',
    ) as UntypedFormControl;
  }
  calculationColorControl(index: number) {
    return this.calculationFormGroup(index).get('color') as UntypedFormControl;
  }
  calculationColumnsControl(index: number) {
    return this.calculationFormGroup(index).get('columns') as FormControl<
      IDataStreamColumn[]
    >;
  }
  calculationExcludeColumnsModeControl(index: number) {
    return this.calculationFormGroup(index).get(
      'excludeColumnsMode',
    ) as FormControl<boolean>;
  }
  calculationIdControl(index: number) {
    return this.calculationFormGroup(index).get('id') as UntypedFormControl;
  }
  calculationFilteredColumnsControl(index: number) {
    return this.calculationFormGroup(index).get(
      'filteredColumns',
    ) as UntypedFormControl;
  }
  calculationColumnOptions(index: number) {
    return this.calculationFormGroup(index).get('columnOptions')
      .value as string[];
  }
  calculationColumnOptionAliasMap(index: number) {
    return this.calculationFormGroup(index).get('columnAliasMap').value;
  }
  // Template helpers
  widgetTypeIs(type: WidgetType) {
    return this.widgetTypeControl?.value === type;
  }

  supportsMultipleCalculations(type: WidgetType) {
    const unsupportedTypes: WidgetType[] = [
      'image',
      'iFrame',
      'staticString',
      'dynamicString',
      'gauge',
      'number',
      'table',
    ];
    return !unsupportedTypes.includes(type);
  }

  supportsXAxis(type: WidgetType) {
    const supportedTypes: WidgetType[] = [
      'line',
      'bar',
      'pie',
      'doughnut',
      'matrix',
      'funnel',
    ];
    return supportedTypes.includes(type);
  }

  supportsDateFilterColumn(calcIndex: number) {
    const xAxis = this.calculationXAxisControl(calcIndex).value;
    if (!this.isDataBasedWidget) return false;
    if (xAxis) {
      const columns = this.calculationColumnsControl(calcIndex).value;
      if (
        columns.find((col) => col.name === xAxis)?.dataType.includes('date')
      ) {
        return false;
      }
    }
    return true;
  }
  showUseXAxisInDashboardDateFilterToggle() {
    const calcIndex = this.selectedCalcIndexControl.value;
    const xAxis = this.calculationXAxisControl(calcIndex).value;
    if (this.isDataBasedWidget && xAxis) {
      const columns = this.calculationColumnsControl(calcIndex).value;
      if (
        columns.find((col) => col.name === xAxis)?.dataType.includes('date')
      ) {
        return true;
      }
    }
    return false;
  }

  supportsSecondaryXAxis(type: WidgetType) {
    const supportedTypes: WidgetType[] = ['line', 'bar'];
    return supportedTypes.includes(type);
  }

  isValid(control: AbstractControl): boolean {
    let isValid = control.valid;
    if (!control.touched) {
      return true;
    }
    return isValid;
  }
  canComparePreviousPeriod() {
    const supportedTypes: WidgetType[] = ['number', 'line', 'bar', 'gauge'];
    let canCompare =
      this.isDataBasedWidget &&
      supportedTypes.includes(this.widgetTypeControl.value);
    if (!canCompare) return false;
    const filter: IPandasFilterV2 =
      this.calculationPandasFilterControl(0).value;
    if (!filter) return false;
    const filterVariables = this.store.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.filterVariableState
          .filterVariables,
    );
    for (const item of filter.items) {
      if (item.useAdvanced && item.advancedValueType == 'between_dates')
        return true;
      if (
        item.useAdvanced &&
        item.advancedValueType == 'filter_variable' &&
        filterVariables.find((v) => v.id == item.advancedValue.variable_id)
          ?.type == 'BETWEEN_DATES'
      ) {
        return true;
      }
    }
    return false;
  }

  // Subscription registration
  async registerInitSubs() {
    this.registerPostWidgetSet();
    this.registerDestroy();
  }
  // Big form listeners
  async registerIndividualFormControlChangeListeners() {
    this.registeredFormControlChangeListeners = true;
    this.reactToWholeFormChanges();
    this.reactToWidgetTypeControlChanges();
    this.reactToDataTypeControlChanges();
    this.reactToTitleControlChanges();
    this.reactToThresholdsControlChanges();
    this.reactToSelectedCalcIndexControlChanges();
    this.reactToCalculationFormArrayChanges();
    this.reactToCustomThresholdsFormArrayChanges();
    this.reactToImageUrlControlChanges();
    this.reactToIFrameUrlControlChanges();
    this.reactToStaticStringControlChanges();
    this.reactToXAxisControlChanges();
    this.reactToYAxisControlChanges();
    this.reactToYMinControlChanges();
    this.reactToYMaxControlChanges();
    this.reactToFontSizeControlChanges();
    this.reactToLegendControlChanges();
    this.reactToOverlayCurrentNumberControlChanges();
    this.reactToDataLabelsControlChanges();
    this.reactToPercentSuffixControlChanges();
    this.reactToDollarPrefixControlChanges();
    this.reactToAbbreviateNumbersControlChanges();
    this.reactToShowHistoricalDataControlChanges();
    this.reactToStringValueSizeControlChanges();
    this.reactToStackingControlChanges();
    this.reactToSortingControlChanges();
    this.reactToTimeIncrementControlChanges();
    this.reactToDailySnapshotTimeCutoffControlChanges();
    this.reactToTimezoneControlChanges();
    this.reactToModalDataSortColumnChanges();
    this.reactToModalDataSortAscendingChanges();
    this.reactToDynamicStringColumnChanges();
    this.reactToCumulativeXAxisChanges();
    this.reactToXAxisBaselineChanges();
    this.reactToXAxisLabelRotationChanges();
    this.reactToPieRadiusChanges();
    this.reactToPieInnerRadiusChanges();
    this.reactToMaxGaugeValueChanges();
    this.reactToMinGaugeValueChanges();
    this.reactToYAxisLogScaleChanges();
    this.reactToLogScaleBaseChanges();
  }

  // Calculations form array listeners
  registerCalculationFormArrayControlChangeListeners(index: number) {
    // this.reactToCalculationGroupChanges(index);
    this.reactToCalculationDataStreamControlChanges(index);
    // this.reactToCalculationNameControlChanges(index);
    this.reactToCalculationXAxisControlChanges(index);
    // this.reactToCalculationGroupingColumnsControlChanges(index);
    // this.reactToCalculationYAxisControlChanges(index);
    // this.reactToCalculationGroupByControlChanges(index);
    // this.reactToCalculationSimpleFilterControlChanges(index);
    this.reactToCalculationPandasFilterControlChanges(index);
    this.reactToCalculationFilterTypeControlChanges(index);
    this.reactToCalculationComputationControlChanges(index);
    this.reactToUseCustomCalculationColumnControlChanges(index);
    this.reactToComputationColumnControlChanges(index);
    this.reactToTimeWindowControlChanges(index);
    // this.reactToDelimiterControlChanges(index);
    this.reactToSelectedColumnsControlChanges(index);
    // this.reactToUseDefaultColorControlChanges(index);
    // this.reactToColorControlChanges(index);
    this.reactToColumnsControlChanges(index);
    this.reactToCustomCalculationColumnControlChanges(index);
  }

  setDisabledOnMissingDataStream() {
    const totalCalcs = this.calculationsFormArray.length;
    for (let i = 0; i < totalCalcs; i++) {
      let action: 'enable' | 'disable';
      if (this.calculationDataStreamControl(i).value?.id) {
        action = 'enable';
      } else if (!this.calculationDataStreamControl(i).value?.id) {
        action = 'disable';
      }

      // These enables/disables will emit events, there are too many executions but they might be needed
      this.calculationNameControl(i)[action]({ emitEvent: false });
      // this.calculationXAxisControl(i)[action]({ emitEvent: false });
      this.calculationGroupByControl(i)[action]({ emitEvent: false });
      this.calculationSimpleFilterControl(i)[action]({ emitEvent: false });
      this.calculationPandasFilterControl(i)[action]({ emitEvent: false });
      // this.calculationFilterTypeControl(i)[action]({ emitEvent: false });
      // this.calculationComputationControl(i)[action]({ emitEvent: false });
      this.calculationComputationColumnControl(i)[action]({ emitEvent: false });
      this.calculationTimeWindowControl(i)[action]({ emitEvent: false });
      // this.calculationDelimiterControl(i)[action]({ emitEvent: false });
    }
  }

  // Subscriptions initiated by NgOnInit
  registerPostWidgetSet() {
    // Handle post widget state set logic
    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(1000, undefined, { trailing: true }),
        ofActionSuccessful(Widget.SetWidget),
        // Map to WidgetStateObject
        map((action) => {
          const storableWidget = action.widget as IStorableWidgetV2;
          const widget = this.store.selectSnapshot(
            (state) =>
              (state.app as IAppStateModel).appState.widgets[storableWidget.id],
          );
          return widget;
        }),
        // Get a preview of the widget if the form is valid
        tap((_) => {
          if (this.changesMadeSinceLastPreview) {
            this.previewCalculations();
          }
        }),
        // Store colors any time a change is detected
        distinctUntilChanged((prev, curr) =>
          deepEqual(prev.colors, curr.colors),
        ),
        tap((_) => {
          this.computeAndStoreWidgetDumbColors();
        }),
      )
      .subscribe();
  }

  registerDestroy() {
    this.isDestroyed$.pipe(take(1)).subscribe((_) => {
      // Clear the widgetId from the query params
      this.router.navigate([], {
        queryParams: {
          widgetId: null,
        },
        queryParamsHandling: 'merge',
      });
      this.store.dispatch(
        new WidgetEditor.OnClose(
          this.widgetId,
          this.isNewWidget,
          this.widgetWasUpdated,
        ),
      );
    });
  }

  // Change listeners for individual form controls
  reactToWholeFormChanges() {
    this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        throttleTime(1000, undefined, { trailing: true }),
        skip(1),
        distinctUntilChanged((prev, curr) => {
          return (
            deepEqual(prev, curr, ['calculationData', 'tableInfo']) &&
            deepEqual(
              this.previousFilter[this.selectedCalcIndexControl.value],
              this.calculationPandasFilterControl(
                this.selectedCalcIndexControl.value,
              ).value,
            )
          );
        }),
        takeUntil(this.isDestroyed$),
        tap(() => {
          // Set formIsValid to false before the debounce time to prevent the user from clicking save
          this.formIsValid = false;
        }),
        pairwise(),
        tap(([prev, curr]) => {
          const errors = getFormValidationErrors(this.form);
          this.formIsValid = errors.length === 0;
          this.widgetIsSavable = this.formIsValid;

          this.changesMadeSinceLastPreview = false;
          // Get an updated preview if the form is valid
          // and any relevant fields have changed
          if (!this.formIsValid) {
            return;
          }
          this.fixMatrixSortCol();
          if (this.isDataBasedWidget && this.step === 'config') {
            // A preview is always needed if the current and previous calculations
            // are not equal
            curr.calculations.forEach((calc, index) => {
              if (!deepEqual(calc, prev.calculations[index])) {
                this.changesMadeSinceLastPreview = true;
              }
            });
            // A preview is needed if the chart type has changed
            if (curr.widgetType !== prev.widgetType) {
              this.changesMadeSinceLastPreview = true;
            }
            // A preview is needed if the timeIncrement has changed
            if (curr.timeIncrement !== prev.timeIncrement) {
              this.changesMadeSinceLastPreview = true;
            }
            // A preview is needed if the modalDataSortColumnControl or modalDataSortAscendingControl has changed
            const keyList = [
              'modalDataSortColumn',
              'modalDataSortAscending',
              'modalDataLimit',
              'transposeTableData',
              'transposeIncludeHeader',
              'transposeHeaderColumnName',
              'transposeColumn',
              'transposePinHeaderColumn',
              'pivotTableData',
              'pivotColumns',
              'pivotValues',
              'pivotIndex',
              'pivotAggFunc',
              'pivotPinIndexColumns',
            ];
            keyList.forEach((key) => {
              if (curr[key] !== prev[key]) {
                this.changesMadeSinceLastPreview = true;
              }
            });

            // A preview is needed if the cumulativeXAxis has changed
            if (curr.cumulativeXAxis !== prev.cumulativeXAxis) {
              this.changesMadeSinceLastPreview = true;
            }
            if (curr.xAxisBaseline !== prev.xAxisBaseline) {
              this.changesMadeSinceLastPreview = true;
            }
            if (curr.fillEmptyPoints !== prev.fillEmptyPoints) {
              this.changesMadeSinceLastPreview = true;
            }

            if (
              !deepEqual(
                this.previousFilter[this.selectedCalcIndexControl.value],
                this.calculationPandasFilterControl(
                  this.selectedCalcIndexControl.value,
                ).value,
              )
            ) {
              this.changesMadeSinceLastPreview = true;
              this.previousFilter[this.selectedCalcIndexControl.value] =
                this.calculationPandasFilterControl(
                  this.selectedCalcIndexControl.value,
                ).value;
            }
          }
        }),
        tap((_) => this.asyncSetWidgetState()),
      )
      .subscribe();
  }
  reactToWidgetTypeControlChanges() {
    this.widgetTypeControl.valueChanges
      .pipe(
        takeUntil(this.isDestroyed$),
        startWith(this.widgetTypeControl.value),
        filter((widgetType) => !!widgetType),
        throttleTime(250, undefined, { trailing: true }),
        tap(() => {
          if (this.isDataBasedWidget) {
            if (this.calculationsFormArray.value.length === 0) {
              this.addCalculation();
            }
          }
        }),
        map((widgetType: WidgetType) => {
          const previousWidgetType = this.previousWidgetType;
          if (previousWidgetType === widgetType) {
            return widgetType;
          }

          const currentWidgetTypeDetails = this.widgetTypeDetails.find(
            (w) => w.value === widgetType,
          );
          const previousWidgetTypeDetails = this.widgetTypeDetails.find(
            (w) => w.value === previousWidgetType,
          );

          if (
            !this.isDataBasedWidget &&
            !this.isNewWidget &&
            this.calculationsFormArray.value.length
          ) {
            // If the widget type is not data based, clear the calculations
            // if there are any calculations. Show a warning if there are calculations
            // that have a valid form.
            const dialogRef = this.dialogService.open(
              UserActionDialogComponent,
              {
                context: {
                  ConfirmText: 'Discard',
                  CancelText: 'Back',
                  TitleText: '(⁠๑⁠•⁠﹏⁠•⁠) Hold up!',
                  ContentText: `Changing the chart type from ${previousWidgetTypeDetails.title} to ${currentWidgetTypeDetails.title} will discard existing configurations. Are you sure you want to proceed?`,
                  ActionType: 'negative',
                },
              },
            );

            dialogRef.onClose.pipe(take(1)).subscribe((confirm) => {
              if (confirm) {
                this.calculationsFormArray.clear();
              } else {
                this.widgetTypeControl.setValue(previousWidgetType);
                return previousWidgetType;
              }
            });
          } else if (
            this.calculationsFormArray.value.length > 1 &&
            !this.supportsMultipleCalculations(widgetType)
          ) {
            // If the widget type is data based and the new widget type does not support multiple calculations
            // show a warning that all but the first calculation will be discarded
            const dialogRef = this.dialogService.open(
              UserActionDialogComponent,
              {
                context: {
                  ConfirmText: 'Discard',
                  CancelText: 'Back',
                  TitleText: '(⁠๑⁠•⁠﹏⁠•⁠) Hold up!',
                  ContentText: `Changing the chart type ${previousWidgetTypeDetails.title} to ${currentWidgetTypeDetails.title} will discard all but the first dataset configuration. Are you sure you want to proceed?`,
                  ActionType: 'negative',
                },
              },
            );

            dialogRef.onClose.pipe(take(1)).subscribe((confirm) => {
              if (confirm) {
                let calculationCount = this.calculationsFormArray.length;
                while (calculationCount > 1) {
                  this.removeCalculation(calculationCount - 1);
                  calculationCount--;
                }
                this.handleWidgetTypeFormRequirements(widgetType);
              } else {
                this.widgetTypeControl.setValue(previousWidgetType);
                return previousWidgetType;
              }
            });
          }

          if (previousWidgetType !== widgetType) {
            // We need to initialize the form
            // with the default settings for the new widget type
            this.form.patchValue({
              ...getDefaultChartSettings(widgetType),
            });
          }
          // } else {
          //   // If the previous widget type is not null, we need to update the form
          //   // with the new widget type
          //   this.initializeForm(newFormState);
          // }
          return widgetType;
        }),
        tap((widgetType) => {
          this.previousWidgetType = widgetType;
        }),
      )
      .subscribe();
  }
  reactToDataTypeControlChanges() {
    this.calculationsTypeControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToTitleControlChanges() {
    this.titleControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToThresholdsControlChanges() {
    this.thresholdsControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  onCustomThresholdValueBlur() {
    const formArray = this.customThresholdsFormArray.value;
    formArray.sort((a, b) => b.value - a.value);
    this.customThresholdsFormArray.patchValue(formArray);
  }
  onCustomCalcThresholdValueBlur(calcId: string) {
    const formArray = this.calcThresholdsCustomControl(calcId).value;
    formArray.sort((a, b) => b.value - a.value);
    this.calcThresholdsCustomControl(calcId).patchValue(formArray);
  }
  reactToSelectedCalcIndexControlChanges() {
    this.setOtherWidgetsAndCalcs();
    this.selectedCalcIndexControl.valueChanges
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((index) => this.setOtherWidgetsAndCalcs()),
        tap((i) => {
          const calc = this.calculationsFormArray.controls[i]
            .value as ICalcFormValues;

          if (calc.xAxis === null && this.isLine) {
            this.calculationXAxisControl(i).setErrors({
              required: true,
            });
          } else {
            this.calculationXAxisControl(i).setErrors(null);
          }
        }),
      )
      .subscribe();
  }
  reactToCalculationFormArrayChanges() {
    this.calculationsFormArray.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => {
        this.setSelectedThresholdsByCalculationsOptions();
      });
  }
  reactToCustomThresholdsFormArrayChanges() {
    this.customThresholdsFormArray.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToImageUrlControlChanges() {
    this.imageUrlControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToIFrameUrlControlChanges() {
    this.iFrameUrlControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToStaticStringControlChanges() {
    this.staticStringControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToXAxisControlChanges() {
    this.xAxisControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToYAxisControlChanges() {
    this.yAxisControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToYMinControlChanges() {
    this.yMinControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToYMaxControlChanges() {
    this.yMaxControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToFontSizeControlChanges() {
    this.fontSizeControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToLegendControlChanges() {
    this.legendControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToOverlayCurrentNumberControlChanges() {
    this.overlayCurrentNumberControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToDataLabelsControlChanges() {
    this.dataLabelsControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToPercentSuffixControlChanges() {
    this.percentSuffixControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToDollarPrefixControlChanges() {
    this.dollarPrefixControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToAbbreviateNumbersControlChanges() {
    this.abbreviateNumbersControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToShowHistoricalDataControlChanges() {
    this.showHistoricalDataControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToRoundingControlChanges() {
    this.roundingControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToStringValueSizeControlChanges() {
    this.stringValueSizeControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToStackingControlChanges() {
    this.groupingControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToSortingControlChanges() {
    this.sortingControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToTimeIncrementControlChanges() {
    this.timeIncrementControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToDailySnapshotTimeCutoffControlChanges() {
    this.dailySnapshotTimeCutoffControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToTimezoneControlChanges() {
    this.timezoneControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToModalDataSortColumnChanges() {
    this.modalDataSortColumnControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToModalDataSortAscendingChanges() {
    this.modalDataSortAscendingControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToDynamicStringColumnChanges() {
    this.dynamicStringColumnControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToCumulativeXAxisChanges() {
    this.cumulativeXAxisControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToXAxisBaselineChanges() {
    this.xAxisBaselineControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToXAxisLabelRotationChanges() {
    this.xAxisLabelRotationControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToPieRadiusChanges() {
    this.pieRadiusControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToPieInnerRadiusChanges() {
    this.pieInnerRadiusControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToMaxGaugeValueChanges() {
    this.maxGaugeValueControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToMinGaugeValueChanges() {
    this.minGaugeValueControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToYAxisLogScaleChanges() {
    this.yAxisLogScaleControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToLogScaleBaseChanges() {
    this.logScaleBaseControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }

  reactToCalculationGroupChanges(i: number) {
    this.calculationFormGroup(i)
      .valueChanges.pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }

  // Change listeners for individual form controls from calculation form array
  reactToCalculationDataStreamControlChanges(i: number) {
    this.calculationDataStreamControl(i)
      .valueChanges.pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(250, undefined, { trailing: true }),
        filter((dataStream) => !!dataStream && dataStream?.id),
        distinctUntilKeyChanged('id'),
        tap((dataStream: IDataStream) => {
          // Clear the data stream columns
          let columnsMatch = true;
          if (!dataStream.columnDetails) {
            columnsMatch = false;
          } else if (this.calculationSelectedColumnsControl(i).value) {
            for (let value of this.calculationSelectedColumnsControl(i).value) {
              if (
                !dataStream.columnDetails?.find(
                  (column) => column.name === value,
                )
              ) {
                columnsMatch = false;
                break;
              }
            }
          }

          if (!columnsMatch) {
            this.calculationSelectedColumnsControl(i).setValue(null, {
              emitEvent: false,
            });
          }

          const dataStreamsState = this.store.selectSnapshot(
            (state) => (state.app as IAppStateModel).appState.dataStreamsState,
          );
          if (!dataStreamsState.isLoading) {
            if (
              !dataStreamsState.dataStreams ||
              !dataStreamsState.dataStreams[dataStream.id] ||
              !dataStreamsState.dataStreams[dataStream.id].columnDetails
            ) {
              this.store.dispatch(
                new App.FetchDataStreamColumns([dataStream.id], true),
              );
            } else {
              let temp_dict = {};
              temp_dict[dataStream.id] =
                dataStreamsState.dataStreams[dataStream.id].columnDetails;
              this.store.dispatch(
                new App.FetchDataStreamColumnsSuccess(temp_dict, false),
              );
            }
          }

          if (!this.calculationNameControl(i).value) {
            this.calculationNameControl(i).patchValue(dataStream.title);
            this.setSelectedThresholdsByCalculationsOptions();
          }
        }),
      )
      .subscribe();

    this.calculationDataStreamControl(i)
      .valueChanges.pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(250, undefined, { trailing: true }),
        // Check if the data stream is a string
        filter((dataStream) => typeof dataStream === 'string'),
        withLatestFrom(this.dataStreamsByType$),
        tap(
          ([filterText, dataStreamsByType]) =>
            (this.dataStreamsByType$ = of(
              this.filter(filterText, dataStreamsByType),
            )),
        ),
      )
      .subscribe();
  }
  // reactToCalculationNameControlChanges(i: number) {
  //   this.calculationNameControl(i).valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe();
  // }
  reactToCalculationXAxisControlChanges(i: number) {
    this.calculationXAxisControl(i)
      .valueChanges.pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(250, undefined, { trailing: true }),
        distinctUntilChanged(),
        tap((xAxis) => {
          const calculationColumns = this.calculationColumnsControl(i).value;
          if (calculationColumns) {
            // If the x axis control is valid, then we can check if it's a date column
            if (this.widgetTypeIs('bar')) {
              if (this.calculationXAxisControl(i).valid) {
                const xAxisColumn = calculationColumns.find(
                  (column) => column.name === xAxis,
                );

                if (
                  xAxisColumn?.dataType === 'date' ||
                  xAxisColumn?.dataType === 'datetime'
                ) {
                  this.showHistoricalDataControl.setValue(true);
                } else {
                  this.showHistoricalDataControl.setValue(false);
                }
              }
            }
          }
        }),
      )
      .subscribe();
  }
  // reactToCalculationGroupByControlChanges(i: number) {
  //   this.calculationGroupByControl(i).valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe();
  // }
  // reactToCalculationSimpleFilterControlChanges(i: number) {
  //   this.calculationSimpleFilterControl(i).valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe();
  // }
  reactToCalculationPandasFilterControlChanges(i: number) {
    this.calculationPandasFilterControl(i)
      .valueChanges.pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }
  reactToCalculationFilterTypeControlChanges(i: number) {
    this.calculationFilterTypeControl(i)
      .valueChanges.pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(250, undefined, { trailing: true }),
        tap((filterType) => {
          // If the filter is null, then we can assume that the user has not selected a filter type yet
          // and we should set the default of the given filter type
          if (filterType === 'simple') {
            if (this.calculationSimpleFilterControl(i).value === null) {
              this.calculationSimpleFilterControl(i).setValue(
                newDefaultSimpleFilter(),
              );
            }
          } else if (filterType === 'pandas') {
            this.calculationPandasFilterControl(i).setValue(
              newDefaultAdvancedFilter(),
              {
                emitEvent: false,
              },
            );
          }
        }),
      )
      .subscribe();
  }
  reactToCalculationComputationControlChanges(i: number) {
    this.calculationComputationControl(i)
      .valueChanges.pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(250, undefined, { trailing: true }),
        tap((compColumn) => {
          if (
            compColumn?.requiresComputationColumn &&
            !this.calculationUseCustomCalculationColumnControl(i).value
          ) {
            this.calculationComputationColumnControl(i).enable();
            this.calculationComputationColumnControl(i).setValidators([
              Validators.required,
            ]);
          } else {
            this.calculationComputationColumnControl(i).disable();
            this.calculationComputationColumnControl(i).clearValidators();
          }
          this.calculationComputationColumnControl(i).updateValueAndValidity();
        }),
      )
      .subscribe();
  }
  reactToUseCustomCalculationColumnControlChanges(i: number) {
    this.calculationUseCustomCalculationColumnControl(i)
      .valueChanges.pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(250, undefined, { trailing: true }),
        tap((useCustomCalculationColumn) => {
          if (
            this.calculationComputationControl(i).value
              ?.requiresComputationColumn &&
            !useCustomCalculationColumn
          ) {
            this.calculationComputationColumnControl(i).enable();
            this.calculationComputationColumnControl(i).setValidators([
              Validators.required,
            ]);
          } else {
            this.calculationComputationColumnControl(i).disable();
            this.calculationComputationColumnControl(i).clearValidators();
          }
          this.calculationComputationColumnControl(i).updateValueAndValidity();
        }),
      )
      .subscribe();
  }
  reactToComputationColumnControlChanges(i: number) {
    this.calculationComputationColumnControl(i)
      .valueChanges.pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(250, undefined, { trailing: true }),
        withLatestFrom(
          this.calculationDataStreamControl(i)
            .valueChanges as Observable<IDataStream>,
        ),
        tap(([filterText, dataStream]) => {
          if (dataStream) {
            const dataStreamColumns = dataStream.columnDetails;
            const filteredColumns = this.filterColumns(
              filterText,
              dataStreamColumns,
            );
            this.calculationFilteredColumnsControl(i).setValue(filteredColumns);
            // Make sure filter text is in the columns list so that we can set
            // the validation error if the user has typed in a column that does not exist
            if (
              filterText &&
              !filteredColumns.find((col) => col.name === filterText)
            ) {
              this.calculationFilteredColumnsControl(i).setErrors({
                invalid: true,
              });
            } else {
              this.calculationFilteredColumnsControl(i).setErrors(null);
            }
          }
        }),
      )
      .subscribe();

    if (
      this.calculationsFormArray.controls.length > 0 &&
      this.calculationsFormArray.controls[0].value.dataStream
    ) {
      this.calculationDataStreamControl(i).setValue(
        this.calculationDataStreamControl(i).value,
      );
    }
  }
  reactToTimeWindowControlChanges(i: number) {
    this.calculationTimeWindowControl(i)
      .valueChanges.pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(250, undefined, { trailing: true }),
        tap((timeWindow: ITrendFreq) => {
          this.timeIncrementControl.setValue(timeWindow.timeIncrement);
        }),
      )
      .subscribe();
  }
  // reactToDelimiterControlChanges(i: number) {
  //   this.calculationDelimiterControl(i).valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe();
  // }
  reactToSelectedColumnsControlChanges(i: number) {
    this.calculationSelectedColumnsControl(i)
      .valueChanges.pipe(takeUntil(this.isDestroyed$))
      .subscribe((curr) => {
        const prev =
          this.calculationPrevColumns[this.calculationIdControl(i).value];
        if (!prev) return;
        if (this.overridePersistColumnOrder) {
          this.overridePersistColumnOrder = false;
          this.calculationSelectedColumnsControl(i).setValue(curr, {
            emitEvent: false,
          });
          return;
        }
        const orderedCols = this.persistColumnOrder(i, prev, curr);
        if (JSON.stringify(orderedCols) !== JSON.stringify(curr)) {
          this.calculationSelectedColumnsControl(i).setValue(orderedCols, {
            emitEvent: false,
          });
        }
      });
  }
  // reactToUseDefaultColorControlChanges(i: number) {
  //   this.calculationUseDefaultColorControl(i).valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe();
  // }
  // reactToColorControlChanges(i: number) {
  //   this.calculationColorControl(i).valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe();
  // }
  reactToColumnsControlChanges(i: number) {
    this.calculationColumnsControl(i)
      .valueChanges.pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(250, undefined, { trailing: true }),
        debounceTime(100),
        tap((columns: IDataStreamColumn[]) => {
          if (columns) {
            this.calculationFilteredColumnsControl(i).setValue(columns, {
              emitEvent: false,
            });
          }
        }),
      )
      .subscribe();
  }

  reactToCustomCalculationColumnControlChanges(i: number) {
    this.calculationCustomCalculationColumnControl(i)
      .valueChanges.pipe(takeUntil(this.isDestroyed$))
      .subscribe();
  }

  // Template helper functions
  dataStreamAutoCompleteDisplayFn(dataStream: IDataStream | string) {
    // Since the dataStreamControl can be a string while the user is typing, we need to check for that
    // and return the string if it is a string.
    return dataStream
      ? typeof dataStream === 'object'
        ? dataStream.title
        : dataStream
      : '';
  }

  /**
   * @param columnDetails
   * @returns A function that displays the column alias if it exists, otherwise the column name
   */
  public createAutoCompleteDisplayFn(columnDetails: IDataStreamColumn[]) {
    // Item is the value returned from the autocomplete
    let displayFunction = (
      item: any,
      cd: IDataStreamColumn[] = columnDetails,
    ) => {
      if (!item) return '';
      if (!cd) return item;
      const columnInfoItem = cd.find((col) => col.name === item);
      return columnInfoItem
        ? columnInfoItem.alias || columnInfoItem.name
        : item;
    };
    return displayFunction;
  }

  columnAliasDisplayFn(column: string) {
    if (!column) return '';
    if (!this.form) return column;
    if (!this.selectedCalcIndexControl) return column;
    // Find the column in the column info list
    const activeCalculationIndex = this.selectedCalcIndexControl
      .value as number;
    if (!activeCalculationIndex) return column;
    const columnInfo = this.calculationColumnsControl(activeCalculationIndex)
      .value as IDataStreamColumn[];
    const columnInfoItem = columnInfo.find((col) => col.name === column);
    if (columnInfoItem) {
      return columnInfoItem.alias || columnInfoItem.name;
    } else {
      return column;
    }
  }

  compareByIdFn(): NbSelectCompareFunction {
    return (a: any, b: any) => a.id === b.id;
  }
  compareByNameFn(): NbSelectCompareFunction {
    return (a: any, b: any) => a.name === b.name;
  }
  compareByValFn(): NbSelectCompareFunction {
    return (a: any, b: any) => a.val === b.val;
  }
  trackByFnForDataStreamAutoComplete(index, item) {
    return item.name;
  }

  fixMatrixSortCol() {
    if (
      this.widgetTypeIs('matrix') &&
      this.modalDataSortColumnControl.value !== '____name' &&
      this.getFormState()
        .calculations.map((calc) => calc.id)
        .indexOf(this.modalDataSortColumnControl.value) === -1
    ) {
      this.modalDataSortColumnControl.setValue('____name');
    }
  }

  setSelectedThresholdsByCalculationsOptions() {
    const calcIds = [];
    this.calculationsFormArray.controls.forEach((calcForm: FormGroup) => {
      calcIds.push(calcForm.value.id);
      const idx = this.selectedThresholdsByCalculationsOptions.findIndex(
        (option) => option.value === calcForm.value.id,
      );
      if (idx === -1) {
        this.selectedThresholdsByCalculationsOptions.push({
          value: calcForm.value.id,
          label: calcForm.controls.name.value,
        });
      } else {
        this.selectedThresholdsByCalculationsOptions[idx].label =
          calcForm.controls.name.value;
      }
    });
    this.selectedThresholdsByCalculationsOptions =
      this.selectedThresholdsByCalculationsOptions.filter((option) =>
        calcIds.includes(option.value),
      );
  }

  // Actions
  getFormState() {
    const formState = this.form.getRawValue();
    return formState;
  }
  addCustomThreshold(calcId?: string) {
    if (calcId) {
      this.calcCustomThresholdsFormArray(calcId).push(
        this.getNewCustomThresholdGroup(calcId),
      );
      // Sort the thresholds by value in descending order
      this.calcCustomThresholdsFormArray(calcId).controls.sort(
        (a, b) => b.get('value').value - a.get('value').value,
      );
    } else {
      this.customThresholdsFormArray.push(this.getNewCustomThresholdGroup(''));
      // Sort the thresholds by value in descending order
      this.customThresholdsFormArray.controls.sort(
        (a, b) => b.get('value').value - a.get('value').value,
      );
    }
  }
  removeCustomThreshold(index: number, calcId?: string) {
    if (calcId) {
      this.calcCustomThresholdsFormArray(calcId).removeAt(index);
    } else {
      this.customThresholdsFormArray.removeAt(index);
    }
  }
  async previewCalculations() {
    this.store.dispatch(new WidgetEditor.GetCalculationPreviews(this.widgetId));
    this.changesMadeSinceLastPreview = false;
  }
  saveWidget() {
    const widget = this.buildWidgetFromFormState();
    if (this.isNewWidget) {
      this.store.dispatch(
        new WidgetEditor.CreateWidget(widget, this.dashboardId),
      );
    } else {
      this.widgetWasUpdated = true;
      this.store.dispatch(new WidgetEditor.UpdateWidget(widget.id));
    }
    this.closeWidgetEditor();
  }
  nextStep(nextStep: WidgetStep) {
    this.step = nextStep;
  }
  selectWidgetType(widgetType: WidgetType) {
    this.widgetTypeControl.setValue(widgetType);
    const isDataBasedWidget =
      widgetType !== 'staticString' &&
      widgetType !== 'iFrame' &&
      widgetType !== 'image';

    this.calculationsTypeControl.setValue('standard');

    if (this.isNewWidget) {
      this.nextStep('config');
    }

    if (isDataBasedWidget && this.isNewWidget) {
      this.store.dispatch(
        new App.FetchDataStreams(false, undefined, true, true),
      );
    }
  }

  private;

  selectCalculationsType(calculationsType: CalculationType) {
    this.calculationsTypeControl.setValue(calculationsType);
    this.nextStep('config');
  }
  async addCalculation(formGroup?: UntypedFormGroup) {
    this.switchingCalculationTabSource$.next(true);
    if (!formGroup) formGroup = this.getNewCalculationFormGroup();
    this.calculationsFormArray.push(formGroup);
    this.calculationOrderControl.setValue(
      this.calculationsFormArray.controls.map((control) => control.value.id),
    );
    const newIndex = this.calculationsFormArray.length - 1;
    this.selectActiveCalculation(newIndex);
    this.registerCalculationFormArrayControlChangeListeners(newIndex);
  }
  removeCalculation(index?: number) {
    if (this.calculationsFormArray.length === 1) {
      this.eventQueue.dispatch('SHOW_TOAST', {
        title: 'Whoops!',
        message: 'Cannot remove the last dataset',
        status: 'info',
      });
      return;
    }
    if (index === undefined) index = this.selectedCalcIndexControl.value;
    const newTargetIndex = index === 0 ? 0 : index - 1;
    this.selectActiveCalculation(newTargetIndex);
    this.calculationsFormArray.removeAt(index);
    this.calculationOrderControl.setValue(
      this.calculationsFormArray.controls.map((control) => control.value.id),
    );
  }
  copyCalculation(index?: number) {
    if (index === undefined) index = this.selectedCalcIndexControl.value;
    const formState = deepcopy(
      this.calculationsFormArray.at(index).getRawValue(),
    );
    // Patch everything except id
    formState.id = v4();
    this.addCalculation(this.getCalculationFormGroup(formState));
  }
  selectActiveCalculation(index: number) {
    this.switchingCalculationTabSource$.next(true);
    this.selectedCalcIndexControl.setValue(index);
    this.setDisabledOnMissingDataStream();
    setTimeout(() => {
      this.switchingCalculationTabSource$.next(false);
    }, 100);
  }

  buildWidgetFromFormState(): IStorableWidgetV2 {
    const formState = this.getFormState() as IWidgetFormValues;
    const colors = this.buildColorsFromFormState();
    const config: IWidgetConfig = {
      id: formState.configId,
      widgetGroupId: formState.groupId,
      xAxis: convertToWidgetConfigOption(formState.xAxis),
      yAxis: convertToWidgetConfigOption(formState.yAxis),
      legend: convertToWidgetConfigOption(formState.legend),
      legendPosition: formState.legendPosition,
      overlayCurrentNumber: formState.overlayCurrentNumber,
      dataLabels: convertToWidgetConfigOption(formState.dataLabels),
      percentSuffix: convertToWidgetConfigOption(formState.percentSuffix),
      dollarPrefix: convertToWidgetConfigOption(formState.dollarPrefix),
      abbreviateNumbers: convertToWidgetConfigOption(
        formState.abbreviateNumbers,
      ),
      showHistoricalData: convertToWidgetConfigOption(
        formState.showHistoricalData,
      ),
      yMax: convertToWidgetConfigOption(formState.yMax),
      yMin: convertToWidgetConfigOption(formState.yMin),
      xLabel: formState.xLabel,
      yLabel: formState.yLabel,
      fontSize: formState.fontSize,
      colorType: convertToWidgetConfigOption(formState.colorType),
      sorting: convertToWidgetConfigOption(formState.sorting),
      groupBySorting: formState.groupBySorting,
      valueLimit: formState.valueLimit,
      barGap: formState.barGap,
      grouping: convertToWidgetConfigOption(formState.grouping),
      showArea: formState.showArea,
      showThresholdMarkers: formState.showThresholdMarkers,
      comparePoints: formState.comparePoints,
      comparisonType: formState.comparisonType,
      comparisonCustomValue: formState.comparisonCustomValue,
      comparisonMethod: formState.comparisonMethod,
      comparisonOnlyShowInLabel: formState.comparisonOnlyShowInLabel,
      stringValueSize: convertToWidgetConfigOption(formState.stringValueSize),
      timeIncrement: convertToWidgetConfigOption(formState.timeIncrement),
      dailySnapshotTimeCutoff: formState.dailySnapshotTimeCutoff,
      timezone: formState.timezone,
      rounding: formState.rounding,
      showWidgetTitle: formState.showWidgetTitle,
      modalDataSortColumn: formState.modalDataSortColumn,
      modalDataSortAscending: formState.modalDataSortAscending,
      modalDataLimit: formState.modalDataLimit,
      cumulativeXAxis: formState.cumulativeXAxis,
      xAxisBaseline: formState.xAxisBaseline,
      xAxisLabelRotation: formState.xAxisLabelRotation,
      pieRadius: formState.pieRadius,
      pieInnerRadius: formState.pieInnerRadius,
      maxGaugeValue: formState.maxGaugeValue,
      minGaugeValue: formState.minGaugeValue,
      yAxisLogScale: formState.yAxisLogScale,
      logScaleBase: formState.logScaleBase,
      calculationOrder: formState.calculationOrder,
      hideZeroLabels: formState.hideZeroLabels,
      showStackTotal: formState.showStackTotal,
      pinnedColumns: formState.pinnedColumns,
      matrixMissingValue: formState.matrixMissingValue,
      matrixIndexLabel: formState.matrixIndexLabel,
      drilldownDateFormat: formState.drilldownDateFormat,
      useCalculationThresholds: formState.useCalculationThresholds,
      transposeTableData: formState.transposeTableData,
      transposeIncludeHeader: formState.transposeIncludeHeader,
      transposeHeaderColumnName: formState.transposeHeaderColumnName,
      transposeColumn: formState.transposeColumn,
      transposePinHeaderColumn: formState.transposePinHeaderColumn,
      pivotTableData: formState.pivotTableData,
      pivotColumns: formState.pivotColumns,
      pivotValues: formState.pivotValues,
      pivotIndex: formState.pivotIndex,
      pivotAggFunc: formState.pivotAggFunc,
      pivotPinIndexColumns: formState.pivotPinIndexColumns,
      columnsWithPercentSuffix: formState.columnsWithPercentSuffix,
      columnsWithDollarPrefix: formState.columnsWithDollarPrefix,
      fillEmptyPoints: formState.fillEmptyPoints,
      horizontalChart: formState.horizontalChart,
      compareWithPreviousPeriod: formState.compareWithPreviousPeriod,
      useXAxisInDashboardDateFilter: formState.useXAxisInDashboardDateFilter,
      fitGridToWidgetWidth: formState.fitGridToWidgetWidth,
    };
    const widget: IStorableWidgetV2 = {
      dashboardV2Id: this.dashboardId,
      id: formState.id,
      widgetType: formState.widgetType,
      gridsterItem: formState.gridsterItem,
      title: formState.title,
      description: formState.description,
      groupId: formState.groupId,
      config,
      iFrameUrl: formState.iFrameUrl,
      imageUrl: formState.imageUrl,
      staticString: formState.staticString,
      dynamicStringColumn: formState.dynamicStringColumn,
      thresholds: formState.thresholds,
      thresholdsByCalculation: formState.thresholdsByCalculation,
      colors,
      calculations: this.buildCalculationsFromFormState(),
      allowedCalculationsType: this.isDataBasedWidget
        ? formState.calculationsType
        : 'none',
      featureItemEnabled: true,
    };
    return widget;
  }

  public buildCalculationsFromFormState(): ICalculationV2Map {
    const formState = this.getFormState() as IWidgetFormValues;
    const calculations: ICalculationV2Map = {};
    const dataStreams = this.store.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.dataStreamsState.dataStreams,
    );
    for (const calculationFormState of formState.calculations) {
      calculations[calculationFormState.id] = convertToCalculationV2(
        calculationFormState,
        formState,
        dataStreams,
      );
    }
    return calculations;
  }

  public buildColorsFromFormState(): IWidgetColors {
    const formState = this.getFormState() as IWidgetFormValues;
    const colors: IWidgetColors = {};
    for (const calculationFormState of formState.calculations) {
      colors[calculationFormState.id] = {
        useDefault: calculationFormState.useDefaultColor,
        color: calculationFormState.color,
      };
    }
    return colors;
  }

  private async asyncSetWidgetState() {
    this.setWidgetState();
  }

  private async setWidgetState() {
    const widget = this.buildWidgetFromFormState();
    this.store.dispatch(new Widget.SetWidget(widget));
  }

  /**
   * The logic determining the active color is done on the widget level and is quite complex.
   * We need to read the color after the widget processes it, and then show it in the live editor UI.
   * This method reads and stores the color map locally to be used after set widget occurs.
   */
  private computeAndStoreWidgetDumbColors() {
    const state = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState,
    );
    const widget = state.widgets[this.widgetId];
    const colorMap = widget.getAllDumbColors();
    this.widgetColorMap = colorMap;
  }

  public getCalculationColor(calcId: string): string {
    const state = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState,
    );
    const widget = state.widgets[this.widgetId];
    const colors = widget.getAllColors();
    const calcColor = colors[calcId];
    if (typeof calcColor === 'object') {
      return Object.values(calcColor)[0];
    } else {
      return calcColor;
    }
  }

  public closeWidgetEditor() {
    this.dialogRef.close();
    this.router.navigate(['dash-v2', this.dashboardId], { queryParams: {} });
  }

  /**
   * Filter data stream group items
   */
  private filterChildren(dataStreams: IDataStream[], filterValue: string) {
    return dataStreams.filter((optionValue) =>
      optionValue.title.toLowerCase().includes(filterValue),
    );
  }
  /**
   * Filter data stream groups
   */
  private filter(value: string, dataStreamsByType): IDataStreamsByType[] {
    const filterValue = value.toLowerCase();
    return dataStreamsByType
      .map((dataStreamType) => {
        return {
          typeTitle: dataStreamType.typeTitle,
          type: dataStreamType.type,
          dataStreams: this.filterChildren(
            dataStreamType.dataStreams,
            filterValue,
          ),
        };
      })
      .filter((group) => group.dataStreams.length);
  }

  /**
   * Filter data stream columns
   */
  private filterColumns(
    value: string,
    dataStreamsColumns: IDataStreamColumn[],
  ): IDataStreamColumn[] {
    if (!value) {
      return dataStreamsColumns;
    }
    const filterValue = value.toLowerCase();
    const columnsFiltered = dataStreamsColumns.filter((optionValue) => {
      return (
        optionValue.name?.toLowerCase().includes(filterValue) ||
        optionValue.alias?.toLowerCase().includes(filterValue)
      );
    });
    return columnsFiltered;
  }

  public logFormState() {
    console.log(this.getFormState());
  }
  public persistColumnOrder(i, oldList: string[], newList: string[]) {
    const orderedList = oldList
      .filter((column) => newList.includes(column))
      .concat(newList.filter((column) => !oldList.includes(column)));
    this.calculationPrevColumns[this.calculationIdControl(i).value] =
      orderedList;
    return orderedList;
  }

  public calculationColumnTrackByFn(index: number, item: IDataStreamColumn) {
    return item.name;
  }

  public openColumnEditor(selectedCalcIndex: number) {
    const drillDownRef = this.dialogService.open(ColumnEditorComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
      context: {
        columnDetails: this.calculationColumnsControl(selectedCalcIndex).value,
        // new object so the original object doesn't get modified
        selectedColumns: [
          ...this.calculationSelectedColumnsControl(selectedCalcIndex).value,
        ],
        excludeMode:
          this.calculationExcludeColumnsModeControl(selectedCalcIndex).value,
      },
    });

    drillDownRef.onClose.subscribe((result) => {
      if (result !== undefined) {
        this.overridePersistColumnOrder = true;
        this.calculationSelectedColumnsControl(selectedCalcIndex).setValue(
          result.columns,
        );
        this.calculationExcludeColumnsModeControl(selectedCalcIndex).setValue(
          result.excludeMode,
        );
      }
    });
  }

  public getDataTypeOfXAxis(calcIndex: number): ColumnDataType {
    const allColumns = this.calculationColumnsControl(calcIndex).value;
    const xAxisColumn = this.calculationXAxisControl(calcIndex).value;
    const xAxisColumnDetails = allColumns.find(
      (column) => column.name === xAxisColumn,
    );
    return xAxisColumnDetails?.dataType;
  }

  public resetCalculationFilteredColumns(calcIndex: number) {
    this.calculationFilteredColumnsControl(calcIndex).setValue(
      this.calculationColumnsControl(calcIndex).value,
      {
        emitEvent: false,
      },
    );
  }

  public onOptionClick(event: MouseEvent) {
    event.stopPropagation();
  }

  public openSoundboard() {
    this.router.navigate(['/manage-soundboard']);
  }

  public playThresholdSound(soundId: string) {
    if (!soundId) return;
    this.httpRequest
      .fetchAudio(soundId)
      .pipe(
        take(1),
        tap((response) => {
          const soundUrl = URL.createObjectURL(response);
          const audio = new Audio(soundUrl);
          audio.load();
          audio.play().catch((error) => {
            console.error('Error playing sound:', error);
          });
        }),
        catchError((error) => {
          console.error('Error fetching sound:', error);
          this.eventQueue.dispatch('SHOW_TOAST', {
            message: 'Error fetching sound',
            status: 'danger',
            title: 'Error',
            duration: 5000,
          });
          return of(error);
        }),
      )
      .subscribe();
  }

  closePopover(popoverRef: NbPopoverDirective): void {
    popoverRef.hide();
  }

  trackByFnForColumn(index: number, item: IDataStreamColumn) {
    return item.name;
  }

  openPercentageFilterDialog(calcIndex: number) {
    const dialogRef = this.dialogService.open(PercentageFilterDialogComponent, {
      context: {
        percentFilter: this.calculationPercentageFilterControl(calcIndex).value,
        columns:
          this.calculationDataStreamControl(calcIndex).value.columnDetails,
      },
    });

    dialogRef.onClose.subscribe((result) => {
      if (result) {
        this.calculationPercentageFilterControl(calcIndex).setValue(result);
      }
    });
  }

  setToPie() {
    this.pieRadiusControl.setValue(70);
    this.pieInnerRadiusControl.setValue(0);
  }
  setToDoughnut() {
    this.pieRadiusControl.setValue(70);
    this.pieInnerRadiusControl.setValue(40);
  }

  copyColumnsFromCalc(widgetAndCalcIds: string[]) {
    const columns = this.store.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.widgets[widgetAndCalcIds[0]]
          .calculations[widgetAndCalcIds[1]].includedColumns,
    );
    this.calculationSelectedColumnsControl(
      this.selectedCalcIndexControl.value,
    ).setValue([]);
    this.calculationSelectedColumnsControl(
      this.selectedCalcIndexControl.value,
    ).setValue([...columns]);
  }
  setOtherWidgetsAndCalcs() {
    this.otherWidgetsAndCalcs = this.store.selectSnapshot((appState) => {
      const state = (appState.app as IAppStateModel).appState;
      const allWidgets = state.widgets;
      const currentDashboard = state.currentDashboard;
      const widgetIds = currentDashboard.widgetIds;
      if (!widgetIds) return [];
      const currentCalc =
        this.calculationsFormArray.controls[this.selectedCalcIndexControl.value]
          ?.value;
      return currentDashboard.widgetIds
        .map((id) => {
          const widget = allWidgets[id];
          const info = { id: widget.id, title: widget.title, calculations: [] };

          for (let calcId in widget.calculations) {
            const calc = widget.calculations[calcId];
            // don't include calcs on different datastreams, also don't show the current calc
            if (
              calc.id === currentCalc?.id ||
              (calc.dataStreamId !== currentCalc?.dataStreamId &&
                currentCalc?.dataStreamId)
            ) {
              continue;
            }

            info.calculations.push({
              id: calcId,
              name: calc.name,
            });
          }
          return info;
        })
        .filter((widget) => widget.calculations.length > 0)
        .sort((a, b) => (a.title > b.title ? 1 : -1));
    });
  }

  toggleShowFilterEditor() {
    this.showFilterEditor = !this.showFilterEditor;
  }

  getDisabledXColumns() {
    const columns = this.calculationColumnsControl(
      this.selectedCalcIndexControl.value,
    ).value as IDataStreamColumn[];

    const disabledColumns = columns
      .filter(
        (column) =>
          (column.possibleValues?.length > 99 ||
            column.dataType !== 'string') &&
          column.dataType !== 'date' &&
          column.dataType !== 'datetime',
      )
      .map((column) => column.name);
    return disabledColumns;
  }

  getDisabledX2Columns() {
    const columns = this.calculationColumnsControl(
      this.selectedCalcIndexControl.value,
    ).value as IDataStreamColumn[];

    const disabledColumns = columns
      .filter(
        (column) =>
          (column.dataType !== 'string' && column.dataType !== 'boolean') ||
          column.possibleValues?.length > 99,
      )
      .map((column) => column.name);
    return disabledColumns;
  }

  getDisabledYColumns() {
    const columns = this.calculationColumnsControl(
      this.selectedCalcIndexControl.value,
    ).value as IDataStreamColumn[];

    const disabledColumns = columns
      .filter(
        (column) =>
          column.dataType === 'string' ||
          column.dataType === 'date' ||
          column.dataType === 'datetime' ||
          column.dataType === 'boolean',
      )
      .map((column) => column.name);
    return disabledColumns;
  }

  getDisabledDateFilterColumns() {
    const columns = this.calculationColumnsControl(
      this.selectedCalcIndexControl.value,
    ).value;

    const disabledColumns = columns
      .filter(
        (column) =>
          column.dataType !== 'date' && column.dataType !== 'datetime',
      )
      .map((column) => column.name);
    return disabledColumns;
  }

  private handleWidgetTypeFormRequirements(widgetType: WidgetType) {
    if (!this.supportsXAxis(widgetType)) {
      // If the new widget type does not support an X axis, clear the X axis
      this.calculationsFormArray.controls.forEach((control, i) => {
        this.calculationXAxisControl(i).setValue(null, {
          emitEvent: false,
        });
      });
    }

    if (!this.supportsSecondaryXAxis(widgetType)) {
      // If the new widget type does not support a secondary X axis, clear the secondary X axis
      this.calculationsFormArray.controls.forEach((control, i) => {
        this.calculationXAxis2Control(i).setValue(null, {
          emitEvent: false,
        });
      });
    }
  }

  onFilterChanged(filter: IPandasFilterV2, calcIndex: number) {
    this.calculationPandasFilterControl(calcIndex).setValue(filter);
  }

  getFilterObject(index: number) {
    this.previousFilter[index] = JSON.parse(
      JSON.stringify(this.calculationPandasFilterControl(index).value),
    );

    return this.calculationPandasFilterControl(index).value;
  }
}
