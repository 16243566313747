import {
  Directive,
  ElementRef,
  NgZone,
  Output,
  OnInit,
  OnDestroy,
  inject,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  shareReplay,
  map,
  throttleTime,
} from 'rxjs/operators';

export interface ElementSize {
  width: number;
  height: number;
}

@Directive({
  selector: '[resplendentObserveResize]',
  standalone: true, // Standard for Angular 17+ directives
  exportAs: 'resplendentObserveResize', // Optional: allows getting a reference in the template
})
export class ObserveResizeDirective implements OnInit, OnDestroy {
  private elementRef = inject(ElementRef<HTMLElement>);
  private zone = inject(NgZone);
  private resizeObserver?: ResizeObserver;
  private resizeSubject = new Subject<ElementSize>();

  /**
   * Observable that emits the element's size {width, height}
   * whenever its dimensions change. It uses ResizeObserver internally.
   * Emits distinct values and replays the latest value for new subscribers.
   */
  @Output('resplendentObserveResize') // Output event matching the selector name
  readonly resize$: Observable<ElementSize> = this.resizeSubject
    .asObservable()
    .pipe(
      // Use borderBoxSize for more accurate visual dimensions (includes padding & border)
      // Fallback to contentRect if borderBoxSize isn't available (older browsers)
      map((entry) => {
        // Modern browsers: use borderBoxSize
        if (entry && (entry as any).borderBoxSize) {
          // borderBoxSize is an array, usually with one item
          const borderBox = (entry as any).borderBoxSize[0];
          return { width: borderBox.inlineSize, height: borderBox.blockSize };
        }
        // Fallback or older browsers: use contentRect
        if (entry && (entry as any).contentRect) {
          return {
            width: (entry as any).contentRect.width,
            height: (entry as any).contentRect.height,
          };
        }
        // Default if ResizeObserverEntry is somehow unexpected
        return {
          width: this.elementRef.nativeElement.offsetWidth,
          height: this.elementRef.nativeElement.offsetHeight,
        };
      }),
      // Throttle the emissions to 100ms
      throttleTime(100, undefined, { leading: true, trailing: true }),
      // Only emit when the width or height actually changes
      distinctUntilChanged(
        (prev, curr) =>
          prev.width === curr.width && prev.height === curr.height,
      ),
      // Share the observable stream and replay the last emission for new subscribers
      shareReplay({ bufferSize: 1, refCount: true }),
    );

  ngOnInit(): void {
    this.observeElement(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.disconnectObserver();
  }

  private observeElement(element: HTMLElement): void {
    if (typeof ResizeObserver === 'undefined') {
      console.warn(
        'ResizeObserver is not supported in this browser. Dimension changes will not be actively monitored.',
      );
      // Optionally, you could emit an initial size here or implement a fallback (less recommended)
      this.resizeSubject.next({
        width: element.offsetWidth,
        height: element.offsetHeight,
      });
      return;
    }

    this.resizeObserver = new ResizeObserver((entries) => {
      if (!entries || entries.length === 0) {
        return;
      }
      // Typically, we only observe one element with this directive instance
      const entry = entries[0];

      // Run inside NgZone because ResizeObserver callbacks run outside
      // of Angular's zone. This ensures change detection triggers properly
      // if subscribers update component properties bound to the template.
      this.zone.run(() => {
        // Pass the raw entry; mapping is done in the pipe
        this.resizeSubject.next(entry as unknown as ElementSize); // Cast needed as types differ
      });
    });

    // Start observing the element's box size changes
    // 'border-box' includes padding and border, which is usually what you want for component dimensions
    // 'content-box' only includes the content area
    // 'device-pixel-content-box' uses device pixels (less common)
    this.resizeObserver.observe(element, { box: 'border-box' });
  }

  private disconnectObserver(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = undefined; // Clean up
    }
    this.resizeSubject.complete(); // Complete the subject
  }

  // Optional: Method to get the current size imperatively if needed
  getCurrentSize(): ElementSize {
    const el = this.elementRef.nativeElement;
    return { width: el.offsetWidth, height: el.offsetHeight };
  }
}
