import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@base-component';
import { IStringMap, ITableModalDataV2 } from '@interfaces';
import { NbDialogRef } from '@nebular/theme';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import {
  BehaviorSubject,
  filter,
  first,
  lastValueFrom,
  Subject,
  take,
} from 'rxjs';
import { EventQueueService } from '../../../../services/event-queue.service';
import { buildModalData } from '../widget-helpers';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { WidgetStateObject } from '@root/state/state-model-objects/widget.state-model';
import { IAppStateModel } from '@root/state/app.model';
import { calcStringDateToDate } from '@helpers';
import { App } from '@root/state/app.actions';

@Component({
  selector: 'resplendent-drilldown-table',
  templateUrl: './drilldown-table.component.html',
  styleUrls: ['./drilldown-table.component.scss'],
})
export class DrilldownTableComponent extends BaseComponent implements OnInit {
  @Input() public modalDataList: ITableModalDataV2[];
  @Input() public modalTitle: string = 'Table';
  @Input() public selectedTarget: {
    label: string;
    calcId: string;
    xValue: string | number;
  };
  @Input() public widgetId: string;
  @Input() public widget: WidgetStateObject;

  public noData: boolean = true;

  private exportTableSource$ = new Subject<null>();
  public exportTable$ = this.exportTableSource$.asObservable();

  public hasLatestData = false;
  private stackedData: { [stackNumber: number]: ITableModalDataV2 } = {};
  private splitData: { [stackNumber: number]: ITableModalDataV2[] } = {};
  private normalData: { [stackNumber: number]: ITableModalDataV2 } = {};
  private dataOrder: {
    [stackNumber: number]: 'normal' | 'segregated stacked' | 'segregated split';
  } = {};
  public selectedData: ITableModalDataV2;

  private tableDataSource$ = new BehaviorSubject<ITableModalDataV2[]>([]);
  public tableData$ = this.tableDataSource$.asObservable();

  public gridApi: GridApi;
  public loadingModalData = false;
  public calcNames: IStringMap = {};
  gridOptions: GridOptions = {
    defaultColDef: {
      width: 150,
      // make every column use 'text' filter by default
      filter: 'agTextColumnFilter',
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
    },
    enableCellTextSelection: true,
    ensureDomOrder: true,
    tooltipShowDelay: 100,
    autoSizeStrategy: {
      type: 'fitCellContents',
    },
  };
  public fullModalData$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.fullModalData,
  );
  loadingFullModal$ = new BehaviorSubject<boolean>(false);
  fullModalButtonStatus = 'basic';
  totalRowCount: number = 0;
  tabRowCounts: { [key: string]: number } = {};

  constructor(
    private dialogRef: NbDialogRef<DrilldownTableComponent>,
    private eventQueue: EventQueueService,
    private store: Store,
    private actions: Actions,
  ) {
    super();
  }

  ngOnInit() {
    this.initializeTable();
    if (this.getSelectedDataLength() === 500) {
      [...Array(8).keys()].map((v, i) => this.flashButtons(i + 2));
    }
  }
  getSelectedDataLength() {
    for (let mdata of this.modalDataList) {
      if (mdata.calcId === this.selectedData?.calcId) return mdata.data.length;
    }
  }
  sidebarTitle(title: string) {
    let dateTitle = calcStringDateToDate(title);
    if (dateTitle) return dateTitle.toLocaleString();
    return title;
  }
  async refreshData() {
    if (!this.selectedTarget) return;
    this.loadingFullModal$.next(true);
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    );
    this.store.dispatch(
      new App.GetFullModalData(
        this.selectedTarget.calcId,
        this.selectedTarget.label,
      ),
    );
    await lastValueFrom(
      this.actions.pipe(
        ofActionSuccessful(App.GetFullModalDataSuccess),
        take(1),
      ),
    );
    const fullModalData = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.fullModalData,
    );
    for (let calcId in fullModalData) {
      const calc = widget.calcArray.find(
        (calc) => calc.activeCalcDataId === calcId,
      );
      if (calc) calc.getActiveData().modal_data = fullModalData[calcId];
    }
    this.stackedData = {};
    this.splitData = {};
    this.normalData = {};
    this.modalDataList = await buildModalData(widget);
    this.initializeTable();
    this.loadingFullModal$.next(false);
  }
  initializeTable() {
    if (!this.modalDataList) return;
    this.calcNames = this.widget.calcArray.reduce((acc, calc) => {
      acc[calc.id] = calc.name;
      return acc;
    }, {});
    // Create the internal data structure that deals with stacked/non stacked/normal data
    for (let modalTableInput of this.modalDataList) {
      if (modalTableInput.data.length > 0) {
        this.noData = false;
      }
      const calc = this.widget.calcArray.find(
        (c) => c.activeCalcDataId === modalTableInput.calcId,
      );
      // Handle segregated data
      const flooredXAxis = calc.xAxis + '_floored';
      const hasFlooredXAxis =
        modalTableInput.data.length !== 0 &&
        flooredXAxis in modalTableInput.data[0];
      let xAxisUniqueValues = [];
      for (let row of modalTableInput.data) {
        if (xAxisUniqueValues.length > 35) break;
        let val = hasFlooredXAxis ? row[flooredXAxis] : row[calc.xAxis];
        if (!xAxisUniqueValues.includes(val)) xAxisUniqueValues.push(val);
      }
      const numberCategoryOrDate =
        calc.calculationType === 'standard' &&
        calc.xAxis &&
        xAxisUniqueValues.length < 35;
      if (modalTableInput.segregationColumn || numberCategoryOrDate) {
        const xAxisDataType = calc.columnInfo.find(
          (c) => c.name === calc?.xAxis,
        ).dataType;
        let {
          colorMap,
          segregationColumn,
          stack,
          data,
          agColumnDefs,
          links,
          dataTitles,
          calcId,
          delimiter,
        } = modalTableInput;
        if (numberCategoryOrDate)
          segregationColumn = hasFlooredXAxis ? flooredXAxis : calc.xAxis;
        // Store the non-stacked version of the data
        this.stackedData[stack] = modalTableInput;
        this.stackedData[stack].isSplitStack = false;
        if (!this.stackedData[stack].color)
          this.stackedData[stack].color = '#696969';

        const splitData: { [segregationKey: string]: IStringMap[] } = {};
        // Split the data based on the segregation column values
        if (delimiter) {
          data.forEach((row) => {
            const segregationKeys = row[segregationColumn]
              ?.split(delimiter)
              .map((s) => s.trim());
            if (!segregationKeys) return;
            segregationKeys.forEach((segregationKey) => {
              if (!splitData[segregationKey]) splitData[segregationKey] = [];
              splitData[segregationKey].push(row);
            });
          });
        } else {
          data.forEach((row) => {
            let segregationKey = row[segregationColumn];
            if (segregationKey === null) segregationKey = 'Blank';
            if (!splitData[segregationKey]) splitData[segregationKey] = [];
            splitData[segregationKey].push(row);
          });
        }

        const segregationKeys = Object.keys(splitData).sort(
          (a, b) => parseFloat(a) - parseFloat(b) || a.localeCompare(b),
        );
        // Create an ITableModalDataV2 instance for each split sub-table
        let isFirstCategory = true;
        segregationKeys.forEach((key) => {
          const _data = splitData[key];
          if (!this.splitData[stack]) this.splitData[stack] = [];
          let color;
          if (!colorMap) color = modalTableInput.color;
          else color = colorMap[key];
          this.splitData[stack].push({
            data: _data,
            delimiter: delimiter,
            agColumnDefs,
            stack,
            color,
            dataTitles,
            subtitle: key,
            links,
            isSplitStack: true,
            calcId,
            xAxisDataType,
            isFirstCategory,
          });
          isFirstCategory = false;
        });
        this.dataOrder[stack] = 'segregated split';
      } else {
        // Handle normal data
        this.normalData[modalTableInput.stack] = modalTableInput;
        this.dataOrder[modalTableInput.stack] = 'normal';
      }
    }
    this.combineTableData();
  }
  CloseDialog() {
    this.dialogRef.close();
  }

  public selectTab(data: ITableModalDataV2) {
    this.selectedData = data;
    this.selectedTarget = {
      label: data?.subtitle,
      calcId: data?.calcId,
      xValue:
        data?.data[0]?.[
          this.widget.calcArray.find((c) => c.activeCalcDataId === data.calcId)
            .xAxis
        ],
    };
  }

  public exportTable() {
    const selectedData = this.selectedData;
    const isStack = typeof selectedData.isSplitStack === 'boolean';
    let isFiveHundredOrMore = false;
    if (isStack) {
      const stack = selectedData.stack;
      const target = this.stackedData[stack];
      isFiveHundredOrMore = target.data.length === 500;
    } else {
      isFiveHundredOrMore = selectedData.data.length === 500;
    }
    if (isFiveHundredOrMore) {
      this.startLoading();
      this.getCsvData();
      this.loadingFullModal$
        .pipe(
          filter((v) => !v),
          first(),
        )
        .subscribe((loading) => {
          setTimeout(() => {
            this.gridApi.exportDataAsCsv({
              fileName: `${selectedData.subtitle}.csv`,
            });
          }, 500);
          this.stopLoading();
        });
    } else {
      this.gridApi.exportDataAsCsv({
        fileName: `${selectedData.subtitle}.csv`,
      });
    }
  }
  public getCsvData() {
    this.eventQueue.dispatch('SHOW_TOAST', {
      duration: 3000,
      title: 'Preparing your data...',
      message: 'This can take a few minutes if your dataset is large',
      status: 'info',
    });
    this.refreshData();
  }
  // Combine all of the data categories into a singe array for use in the table
  private combineTableData() {
    const dataOrder = this.dataOrder;
    const tableData: ITableModalDataV2[] = [];
    const stacks = Object.keys(dataOrder).map((x) => parseInt(x));
    stacks.forEach((stack) => {
      const type = dataOrder[stack];
      if (type === 'normal') {
        tableData.push(this.normalData[stack]);
      } else if (type === 'segregated stacked') {
        tableData.push(this.stackedData[stack]);
      } else if (type === 'segregated split') {
        const splitData = this.splitData[stack];
        if (!splitData) return;
        splitData.forEach((d) => tableData.push(d));
      } else {
        throw new Error(`Unknown type "${type}"`);
      }
    });
    if (tableData.length === 0) {
      this.noData = true;
      return;
    }

    // Calculate total rows from the original unsplit data
    this.totalRowCount = 0;
    stacks.forEach((stack) => {
      // Always use stacked data for total count since it represents the unsplit data
      if (this.stackedData[stack]) {
        this.totalRowCount += this.stackedData[stack].data.length;
      } else if (this.normalData[stack]) {
        this.totalRowCount += this.normalData[stack].data.length;
      }
    });

    // Calculate individual tab row counts
    this.tabRowCounts = {};
    tableData.forEach((data) => {
      const key = `${data.calcId}_${data.subtitle}`;
      this.tabRowCounts[key] = data.data.length;
    });

    this.tableDataSource$.next(tableData);
    const defaultSelection = tableData[0];
    const defaultSelectedTarget = {
      label: defaultSelection.subtitle,
      calcId: defaultSelection.calcId,
      xValue: defaultSelection.data[0]?.[this.widget.calcArray[0].xAxis],
    };

    if (!defaultSelection) return;
    if (!this.selectedTarget) {
      this.selectedTarget = defaultSelectedTarget;
    }
    const xValue = this.selectedTarget.xValue;
    this.selectedData = this.pickSelectedData(xValue, tableData);
    if (!this.selectedData) {
      this.selectedTarget = defaultSelectedTarget;
      this.selectedData = this.pickSelectedData(xValue, tableData);
      this.eventQueue.dispatch('SHOW_TOAST', {
        duration: 8000,
        title: 'Heads up!',
        message:
          'The item you were drilling down on is not available. This can happen if you clicked on a point with 0 or null values.',
        status: 'info',
      });
    }
  }
  flashButtons(delay) {
    setTimeout(() => {
      this.fullModalButtonStatus = 'warning';
      setTimeout(() => {
        this.fullModalButtonStatus = 'basic';
      }, 300);
    }, delay * 1000);
  }

  private pickSelectedData(
    xValue: number | string,
    tableData: ITableModalDataV2[],
  ) {
    if (xValue === null) xValue = 'Blank';
    let data;
    if (typeof xValue === 'number') {
      data = tableData.find((x) => {
        const date = new Date(x.subtitle);
        const timezoneOffset = date.getTimezoneOffset();
        const utcDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);
        return (
          utcDate.toLocaleString() == new Date(xValue).toLocaleString() &&
          x.calcId === this.selectedTarget.calcId
        );
      });
    }
    if (typeof xValue === 'number' && !data) {
      data = tableData.find(
        (x) =>
          parseFloat(x.subtitle) === xValue &&
          x.calcId === this.selectedTarget.calcId,
      );
    }
    if (!data) {
      data = tableData.find(
        (x) =>
          (x.subtitle === xValue || x.subtitle === this.selectedTarget.label) &&
          x.calcId === this.selectedTarget.calcId,
      );
    }
    return data;
  }

  // Split or combine the selected data
  public toggleSegregation() {
    const selectedData = this.selectedData;
    if (selectedData.isSplitStack === undefined) {
      throw new Error(
        "Segregate toggle shouldn't be active when a non-segregated table is on screen",
      );
    }
    const isSegregated = selectedData.isSplitStack;
    const stack = selectedData.stack;
    let newSelectedData: ITableModalDataV2;
    if (isSegregated) {
      // Stack the data
      this.dataOrder[stack] = 'segregated stacked';
      newSelectedData = this.stackedData[stack];
      this.totalRowCount = newSelectedData?.data?.length;
    } else {
      // Split the data, default to the first split item on stack split
      this.dataOrder[stack] = 'segregated split';
      newSelectedData = this.splitData[stack][0];
      this.totalRowCount = newSelectedData?.data?.length;
    }
    this.selectedTarget = {
      label: newSelectedData.subtitle,
      calcId: newSelectedData.calcId,
      xValue: newSelectedData.data[0][this.widget.calcArray[0].xAxis],
    };
    this.combineTableData();
  }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  validDate(date: string) {
    return new Date(date).toLocaleString() !== 'Invalid Date';
  }
}
