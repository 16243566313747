import {
  CalculationOperationType,
  CalculationType,
  ICalculationOperation,
  ICalculationV2,
  IDataStreamMap,
  IFilter,
  IGeneralMap,
  IStorableWidgetV2,
  IThresholdsV2,
  ITrendFreq,
  IWidgetColors,
  IWidgetConfigOption,
  StdCalcTimeIncrement,
  WidgetType,
} from '@interfaces';
import {
  defaultColumnOperation,
  defaultPandasFilterV2,
  defaultSimpleFilter,
} from '@root/custom_scripts/config';
import { getDefaultChartColors } from 'environments/default-settings/default-settings';
import {
  AllValidationErrors,
  FormGroupControls,
  ICalcFormValues,
  IWidgetFormValues,
} from './typing.interface';

import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import deepcopy from 'deepcopy';
import { v4 } from 'uuid';
import { WidgetStateObject } from '@root/state/state-model-objects/widget.state-model';
export const conditionalValidator = (
  predicate: () => boolean,
  validator: ValidatorFn,
  errorNamespace?: string,
): ValidatorFn => {
  return (formControl) => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    if (errorNamespace && error) {
      const customError = {};
      customError[errorNamespace] = error;
      error = customError;
    }
    return error;
  };
};

export const flattenForm = (
  form: UntypedFormGroup | UntypedFormArray,
  parentKey?: string,
): FormGroupControls => {
  let flattenedControls: FormGroupControls = {};
  Object.entries(form.controls).forEach(([key, control]) => {
    let adjustedKey = parentKey ? `${parentKey}.${key}` : key;
    if (control instanceof UntypedFormArray) {
      control.controls.forEach((arrayControl, i) => {
        adjustedKey = `${adjustedKey}.${i}`;
        flattenedControls = {
          ...flattenedControls,
          ...flattenForm(arrayControl as UntypedFormGroup, adjustedKey),
        };
      });
    } else if (control instanceof UntypedFormGroup) {
      flattenedControls = {
        ...flattenedControls,
        ...flattenForm(control, adjustedKey),
      };
    } else if (control instanceof UntypedFormControl) {
      flattenedControls[adjustedKey] = control;
    }
  });
  return flattenedControls;
};

export const getFormValidationErrors = (
  formGroup: UntypedFormGroup,
): AllValidationErrors[] => {
  let errors: AllValidationErrors[] = [];
  const controls = flattenForm(formGroup);
  Object.keys(controls).forEach((key) => {
    const controlErrors: ValidationErrors = controls[key].errors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach((keyError) => {
        errors.push({
          controlName: key,
          errorName: keyError,
          errorValue: controlErrors[keyError],
        });
      });
    }
  });
  return errors;
};

export const getComputationConstants = (): IGeneralMap<
  CalculationOperationType,
  ICalculationOperation
> => {
  return {
    live: {
      val: 'live',
      label: 'Count',
      message:
        'Gets the number of rows in your dataset that meet your data modifier.',
      requiresComputationColumn: false,
    },
    sum: {
      val: 'sum',
      label: 'Sum',
      message: 'Gets the sum of values on a selected column.',
      requiresComputationColumn: true,
    },
    average: {
      val: 'average',
      label: 'Average',
      message: 'Gets the average value of a column in your dataset',
      requiresComputationColumn: true,
    },
    custom: {
      val: 'custom',
      label: 'Custom',
      message: 'Create your own mix of computations',
      requiresComputationColumn: false,
    },
    median: {
      val: 'median',
      label: 'Median',
      message: 'Gets the median value of a column in your dataset',
      requiresComputationColumn: true,
    },
    max: {
      val: 'max',
      label: 'Max',
      message: 'Gets the largest value of a column in your dataset',
      requiresComputationColumn: true,
    },
    min: {
      val: 'min',
      label: 'Min',
      message: 'Gets the smallest value of a column in your dataset',
      requiresComputationColumn: true,
    },
    mode: {
      val: 'mode',
      label: 'Mode',
      message: 'Gets the most common value of a column in your dataset',
      requiresComputationColumn: true,
    },
    range: {
      val: 'range',
      label: 'Range',
      message: 'Gets the difference between the max and min values',
      requiresComputationColumn: true,
    },
    percentage: {
      val: 'percentage',
      label: 'Percentage',
      message: `Gets the percentage of rows from your dataset that meet a secondary filter`,
      requiresComputationColumn: false,
    },
  };
};
export interface IWidgetTypeDetails {
  value: WidgetType;
  title: string;
  description: string;
  svgIcon: string;
  features: string[];
}
// "number" | "bar" | "pie" | "line" | "table" | "doughnut" | "iFrame" | "image"
export const getWidgetTypeDetails = (): IWidgetTypeDetails[] => {
  return [
    {
      value: 'bar',
      title: 'Bar Chart',
      description: 'Display your data in a bar chart',
      svgIcon: 'bar-chart-outline',
      features: [
        'View current data or historical data',
        'Use thresholds to color your bars dynamically',
        'Sort your data',
        'Group data by stacking bars',
      ],
    },
    {
      value: 'line',
      title: 'Line Chart',
      description: 'Display your data in a line chart',
      svgIcon: 'line-chart',
      features: [
        'View historical data',
        'Use thresholds to color the line as a gradient',
      ],
    },
    {
      value: 'pie',
      title: 'Pie Chart',
      description: 'Display your data in a pie chart',
      svgIcon: 'pie-chart-outline',
      features: ['View current data', 'Sort your data'],
    },
    {
      value: 'table',
      title: 'Table',
      description: 'Display your data in a table',
      svgIcon: 'table',
      features: ['View current data', 'Search, sort, and filter your data'],
    },
    {
      value: 'number',
      title: 'Number',
      description: 'Display as a single number',
      svgIcon: 'single-number',
      features: [
        'View current data',
        'Big bold single calculation number',
        'Use thresholds to color your number dynamically',
      ],
    },
    {
      value: 'gauge',
      title: 'Gauge',
      description: 'Display as a gauge',
      svgIcon: 'gauge',
      features: ['View current data', 'Set a min and max value'],
    },
    {
      value: 'iFrame',
      title: 'External Website',
      description: 'Display an external website or web app',
      svgIcon: 'link-outline',
      features: [
        'View and use any publicly accessible website',
        "Useful for displaying anything that resplendent can't realistically provide",
        'External web content, use only trusted sources',
      ],
    },
    {
      value: 'image',
      title: 'Image',
      description: 'Display a publicly hosted image',
      svgIcon: 'image-outline',
      features: [
        'View any publicly accessible image',
        'Add your logo, picture or gif',
        'Useful for stylizing your dashboard, and memes',
      ],
    },
    {
      value: 'staticString',
      title: 'Static Value',
      description: 'Display a static value',
      svgIcon: 'text-outline',
      features: [
        'Display a company slogan or quote of the week',
        'Display a goal with a number',
        'Highlight employee of the month',
        'Display a dank meme',
      ],
    },
    {
      value: 'dynamicString',
      title: 'Dynamic Value',
      description: 'Display a value from your data',
      svgIcon: 'dynamic-string',
      features: [
        'Display the ticket number or summary of your latest ticket',
        'Display the name of the employee with the most completed tickets',
      ],
    },
    {
      value: 'matrix',
      title: 'Matrix',
      description: 'Display a matrix of calculations in a table',
      svgIcon: 'matrix',
      features: [
        'Show number of open tickets, tickets closed today, ect... for each employee',
      ],
    },
    {
      value: 'funnel',
      title: 'Funnel',
      description: 'Display a funnel chart',
      svgIcon: 'funnel-outline',
      features: ['View the steps in a process', 'Sort your data', 'Group data'],
    },
  ];
};

export interface ICalculationTypeDetails {
  value: CalculationType;
  title: string;
  svgIcon: string;
  description: string;
}

export const getCalculationTypeDetails = (): ICalculationTypeDetails[] => {
  return [
    {
      value: 'standard',
      title: 'Standard Widget',
      svgIcon: 'line-chart',
      description: `Standard widgets are a window to the current state of your data. You can condense a data stream to one or multiple values, such as "Total Open Tickets" or "Tickets Closed Per Person". You can also view historical data by using a created_on column as the X axis of your data.`,
    },
    {
      value: 'snapshot',
      title: 'Snapshot Widget',
      svgIcon: 'camera-outline',
      description: `Snapshot widgets are recorded over time. They are more expensive to compute, but offer features that your database might not have. You can condense a data stream to a single or multiple values, such as "Total Open Tickets This Week" or "Tickets Closed Per Person This Week". With snapshot widgets you can track changes without your data source explicitly recording it.`,
    },
  ];
};

export const timeSpanValueMap: ITrendFreq[] = [
  {
    between_dates: {
      start_unit: 'minute',
      start_offset: -30,
      end_unit: 'minute',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'FREQ=MINUTELY;INTERVAL=1',
    points: 30,
    label: 'Last 30 minutes',
    value: '30_mins',
    timeIncrement: 'minute',
  },
  {
    between_dates: {
      start_unit: 'hour',
      start_offset: -5,
      end_unit: 'hour',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'FREQ=MINUTELY;INTERVAL=10',
    points: 30,
    label: 'Last 5 hours',
    value: '5_hours',
    timeIncrement: 'minute',
  },
  {
    between_dates: {
      start_unit: 'hour',
      start_offset: -24,
      end_unit: 'hour',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'FREQ=HOURLY;INTERVAL=1',
    points: 24,
    label: 'Last 24 hours',
    value: '24_hours',
    timeIncrement: 'hour',
  },
  {
    between_dates: {
      start_unit: 'day',
      start_offset: -6,
      end_unit: 'day',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'FREQ=DAILY;INTERVAL=1',
    points: 7,
    label: 'Last 7 days',
    value: '7_days',
    timeIncrement: 'day',
  },
  {
    between_dates: {
      start_unit: 'day',
      start_offset: -30,
      end_unit: 'day',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'FREQ=DAILY;INTERVAL=1',
    points: 30,
    label: 'Last 30 days',
    value: '30_days',
    timeIncrement: 'day',
  },
  {
    between_dates: {
      start_unit: 'month',
      start_offset: -2,
      end_unit: 'month',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'FREQ=DAILY;INTERVAL=3',
    points: 30,
    label: 'Last 3 months',
    value: '3_months',
    timeIncrement: 'week',
  },
  {
    between_dates: {
      start_unit: 'month',
      start_offset: -5,
      end_unit: 'month',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'FREQ=DAILY;INTERVAL=6',
    points: 30,
    label: 'Last 6 months',
    value: '6_months',
    timeIncrement: 'month',
  },
  {
    between_dates: {
      start_unit: 'month',
      start_offset: -12,
      end_unit: 'month',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'FREQ=DAILY;INTERVAL=12',
    points: 30,
    label: 'Last 12 months',
    value: '12_months',
    timeIncrement: 'month',
  },
  {
    between_dates: {
      start_unit: 'day',
      start_offset: 0,
      end_unit: 'day',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'none',
    points: 24,
    label: 'Today',
    value: 'today',
    timeIncrement: 'hour',
  },
  {
    between_dates: {
      start_unit: 'week',
      start_offset: 0,
      end_unit: 'week',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'none',
    points: 30,
    label: 'This Week',
    value: 'this_week',
    timeIncrement: 'day',
  },
  {
    between_dates: {
      start_unit: 'month',
      start_offset: 0,
      end_unit: 'month',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'none',
    points: 31,
    label: 'This Month',
    value: 'this_month',
    timeIncrement: 'day',
  },
  {
    between_dates: {
      start_unit: 'quarter',
      start_offset: 0,
      end_unit: 'quarter',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'none',
    points: 36,
    label: 'This Quarter',
    value: 'this_quarter',
    timeIncrement: 'month',
  },
  {
    between_dates: {
      start_unit: 'year',
      start_offset: 0,
      end_unit: 'year',
      end_offset: 0,
      timezone: 'UTC',
    },
    rrule: 'none',
    points: 36,
    label: 'This Year',
    value: 'this_year',
    timeIncrement: 'month',
  },
];

const anchoredTimeSpanKeys = {
  today: true,
  this_week: true,
  this_month: true,
  this_quarter: true,
  this_year: true,
};
const rollingTimeSpanKeys = {
  '30_mins': true,
  '5_hours': true,
  '24_hours': true,
  '7_days': true,
  '30_days': true,
  '3_months': true,
  '6_months': true,
  '12_months': true,
};
const timeIncrementValues: StdCalcTimeIncrement[] = [
  'minute',
  'hour',
  'day',
  'week',
  'month',
  'quarter',
  'year',
];

export const getAnchoredTimeSpans = (): ITrendFreq[] => {
  return timeSpanValueMap.filter(
    (timeSpan) => anchoredTimeSpanKeys[timeSpan.value],
  );
};

export const getRollingTimeSpans = (): ITrendFreq[] => {
  return timeSpanValueMap.filter(
    (timeSpan) => rollingTimeSpanKeys[timeSpan.value],
  );
};

export const getTimeIncrementValues = (): StdCalcTimeIncrement[] => {
  return [...timeIncrementValues];
};

export const getDefaultThresholds = (): IThresholdsV2 => {
  return {
    bottom: getDefaultChartColors(4),
    custom: [],
    snooze: 300, // 5 minutes
    allowMute: true,
  };
};
export type IThresholdTooltipsMap = {
  [key in 'number' | 'line' | 'bar' | 'matrix']: {
    top?: string;
    bottom: string;
    custom: string;
  };
};
export const getThresholdTooltips = (): IThresholdTooltipsMap => {
  return {
    line: {
      top: 'Top color of the gradient',
      custom: 'In-between colors of the gradient',
      bottom: 'Bottom color of the gradient',
    },
    bar: {
      bottom: 'Color of the bar when bellow any threshold',
      custom: 'Color of the bar at or above this threshold value',
    },
    number: {
      bottom: 'Color of the bar when bellow any threshold',
      custom: 'Color of the bar at or above this threshold value',
    },
    matrix: {
      bottom: 'Color of the number/box when bellow any threshold',
      custom: 'Color of the number/box at or above this threshold value',
    },
  };
};

export const getFormTooltips = () => {
  return {
    title: 'The name of this widget',
    usingColors:
      'Use colors for specific data streams, or individual data stream values',
    usingThresholds:
      'Use thresholds to color your data based on its value (anything green until 100, red after 100)',
    imageUrl:
      'URL to a publicly hosted image. Make sure to use only trusted sources',
    iframeUrl:
      'URL to your external website. Make sure to use only trusted sources. We are not responsible for malicious code an external website widget could introduce',
    xAxis: 'Display the X axis on the chart',
    yAxis: 'Display the Y axis on the chart',
    legend: 'Show the legend for this chart',
    dataLabels: 'Show values on the chart itself',
    grouping: 'Stack bars on top of each other',
    sorting: 'Sort the data in the chart',
    valueLimit: 'Limit the number of values displayed in the chart',
    barGap: 'The space between bars in a bar chart',
    // Calc tooltips
    dataStream:
      'A data stream is a source of data, it can either be a data modifier or a data source. You can have multiple data streams in a single widget',
    name: 'Label this data stream for easy identification',
    calcXAxis: 'Values you want to display on the X axis',
    groupingColumns: '???',
    groupBy:
      'Show multiple values on the X axis by grouping them using a common denominator. Example: Group by category will show a chart item for each category',
    simpleFilter: 'Basic filter with IS or IS NOT operations',
    pandasFilter:
      'Advanced filter, allowing chained filters with AND, OR and parentheses',
    computation: 'The calculation to perform on the data',
    computationColumn: 'The target column to perform the calculation on',
    timeWindow: 'The time window of snapshots to be displayed',
    delimiter: 'Optional delimiter if your data is messy',
  };
};

// Fetch default filter objects
export const newDefaultAdvancedFilter = () => {
  return deepcopy(defaultPandasFilterV2);
};
export const newDefaultSimpleFilter = () => deepcopy(defaultSimpleFilter);

export const getDefaultCalcFormValues = (): ICalcFormValues => {
  return {
    dataStream: null,
    name: '',
    xAxis: null,
    xAxis2: null,
    // groupingColumns: null,
    groupBy: null,
    simpleFilter: null,
    pandasFilter: null,
    filterType: 'none',
    percentFilter: null,
    computation: getComputationConstants().live,
    computationColumn: null,
    timeWindow: getRollingTimeSpans()[0],
    useDefaultColor: true,
    color: '#0095FF',
    delimiter: null,
    delimiter2: null,
    selectedColumns: [],
    id: v4(),
    columns: [],
    excludeColumnsMode: false,
    filteredColumns: [],
    columnOptions: [],
    columnAliasMap: {},
    useCustomCalculationColumn: false,
    customCalculationColumn: defaultColumnOperation as any,
    dateFilterColumn: null,
  };
};

export const getDefaultFormValues = (): IWidgetFormValues => {
  return {
    widgetType: null,
    calculationsType: null,
    calculations: [],
    title: 'New Widget',
    description: null,
    thresholds: getDefaultThresholds(),
    timezone: 'UTC',
    selectedCalcIndex: 0,
    colorType: 'colors',
    imageUrl: null,
    iFrameUrl: null,
    staticString: null,
    xAxis: false,
    yAxis: false,
    yMax: null,
    yMin: null,
    xLabel: null,
    yLabel: null,
    fontSize: null,
    legend: false,
    legendPosition: 'top',
    overlayCurrentNumber: false,
    dataLabels: false,
    percentSuffix: false,
    dollarPrefix: false,
    abbreviateNumbers: true,
    showHistoricalData: false,
    rounding: 2,
    showWidgetTitle: true,
    stringValueSize: null,
    grouping: 'none',
    showArea: false,
    showThresholdMarkers: true,
    comparePoints: false,
    comparisonType: 'previous',
    comparisonCustomValue: 0,
    comparisonMethod: 'percent',
    comparisonOnlyShowInLabel: false,
    sorting: 'default',
    groupBySorting: 'default',
    valueLimit: null,
    barGap: 0,
    timeIncrement: 'day',
    modalDataSortColumn: null,
    modalDataSortAscending: false,
    modalDataLimit: null,
    dynamicStringColumn: null,
    cumulativeXAxis: false,
    dailySnapshotTimeCutoff: '23:59:59',
    xAxisBaseline: null,
    xAxisLabelRotation: 10,
    pieRadius: 70,
    pieInnerRadius: 0,
    maxGaugeValue: 100,
    minGaugeValue: 0,
    yAxisLogScale: false,
    logScaleBase: 10,
    hideZeroLabels: false,
    showStackTotal: false,
    pinnedColumns: [],
    matrixMissingValue: '0',
    matrixIndexLabel: 'name',
    drilldownDateFormat: 'local',
    useCalculationThresholds: false,
    transposeTableData: false,
    transposeIncludeHeader: true,
    transposeHeaderColumnName: 'column',
    transposeColumn: null,
    transposePinHeaderColumn: false,
    pivotTableData: false,
    pivotColumns: [],
    pivotValues: [],
    pivotIndex: [],
    pivotAggFunc: 'sum',
    pivotPinIndexColumns: false,
    thresholdsByCalculation: {},
    columnsWithDollarPrefix: [],
    columnsWithPercentSuffix: [],
    fillEmptyPoints: true,
    compareWithPreviousPeriod: null,
    useXAxisInDashboardDateFilter: true,
    horizontalChart: false,
    fitGridToWidgetWidth: false,
    id: v4(),
    groupId: v4(),
    configId: v4(),
    gridsterItem: null,
    calculationOrder: [],
  };
};

export const getDefaultChartSettings = (widgetType: WidgetType) => {
  if (widgetType === 'line') {
    return {
      xAxis: true,
      yAxis: true,
      legend: true,
      dataLabels: true,
      xAxisLabelRotation: 0,
      horizontalChart: false,
    };
  } else if (widgetType === 'bar') {
    return {
      xAxis: true,
      yAxis: true,
      yMin: 0,
      legend: true,
      dataLabels: true,
      barGap: 20,
      xAxisLabelRotation: 0,
    };
  } else if (widgetType === 'pie') {
    return {
      xAxis: false,
      yAxis: false,
      legend: false,
      dataLabels: true,
      colorType: 'colors',
      horizontalChart: false,
    };
  } else if (widgetType === 'funnel') {
    return {
      xAxis: false,
      yAxis: false,
      legend: true,
      dataLabels: true,
      colorType: 'colors',
      horizontalChart: false,
      sorting: 'descending',
    };
  } else if (widgetType === 'number') {
    return {
      rounding: 1,
    };
  } else if (widgetType === 'table' || widgetType === 'dynamicString') {
    return {};
  } else if (
    widgetType === 'iFrame' ||
    widgetType === 'image' ||
    widgetType === 'staticString'
  ) {
    return {
      showWidgetTitle: false,
    };
  } else {
    return {};
  }
};

export const convertToWidgetConfigOption = (
  value: boolean | any,
): IWidgetConfigOption => {
  if (typeof value === 'boolean') {
    return { toggled: value };
  }
  // We can assume the setting is turned on if it's truthy, but zero is also a valid value
  const toggled = !!value || typeof value === 'number';
  return { toggled, value };
};

export const convertToCalculationV2 = (
  calculation: ICalcFormValues,
  widget: IWidgetFormValues,
  dataStreams: IDataStreamMap,
): ICalculationV2 => {
  const filter: IFilter = {
    simpleFilter:
      calculation.filterType === 'simple' ? calculation.simpleFilter : null,
    pandasFilter:
      calculation.filterType === 'pandas' ? calculation.pandasFilter : null,
    advancedFilter: calculation.filterType === 'pandas',
  };
  const dataStreamId = calculation.dataStream?.id;
  const dataStream = dataStreams[dataStreamId];
  const includedColumns = calculation.selectedColumns;

  return {
    id: calculation.id,
    dataStreamId: dataStreamId,
    dataStreamType: dataStream?.type,
    widgetGroupId: widget.groupId,
    name: calculation.name,
    calculationType: widget.calculationsType,
    calculationOperation: calculation.computation?.val,
    calculationColumn: calculation.computationColumn,
    useFilter: calculation.filterType !== 'none' && !!calculation.filterType,
    filter,
    percentFilter: calculation.percentFilter,
    includedColumns,
    excludeColumnsMode: calculation.excludeColumnsMode,
    xAxis: calculation.xAxis,
    xAxis2: calculation.xAxis2,
    delimiter2: calculation.delimiter2,
    trendFreq: calculation.timeWindow,
    delimiterEnabled: !!calculation.delimiter,
    delimiter: calculation.delimiter,
    groupBy: calculation.groupBy,
    useCustomCalculationColumn: calculation.useCustomCalculationColumn,
    customCalculationColumn: calculation.customCalculationColumn,
    dateFilterColumn: calculation.dateFilterColumn,
  };
};

export const getFormValuesFromStorableWidget = (
  widget: WidgetStateObject,
  dataStreams: IDataStreamMap,
): IWidgetFormValues => {
  const formValues: IWidgetFormValues = {
    widgetType: widget.widgetType,
    calculationsType: widget.allowedCalculationsType,
    calculations: widget.calcArray.map((calc) =>
      getFormValuesFromStorableCalc(calc, dataStreams, widget.colors),
    ),
    title: widget.title,
    description: widget.description,
    thresholds: widget.thresholds,
    selectedCalcIndex: 0,
    colorType: widget.config.colorType.value,
    imageUrl: widget.imageUrl,
    iFrameUrl: widget.iFrameUrl,
    staticString: widget.staticString,
    xAxis: widget.config.xAxis.toggled,
    yAxis: widget.config.yAxis.toggled,
    yMax: widget.config.yMax.value,
    yMin: widget.config.yMin.value,
    xLabel: widget.config.xLabel,
    yLabel: widget.config.yLabel,
    fontSize: widget.config.fontSize,
    legend: widget.config.legend.toggled,
    legendPosition: widget.config.legendPosition,
    overlayCurrentNumber: widget.config.overlayCurrentNumber,
    dataLabels: widget.config.dataLabels.toggled,
    percentSuffix: widget.config.percentSuffix.toggled,
    dollarPrefix: widget.config.dollarPrefix.toggled,
    abbreviateNumbers: widget.config.abbreviateNumbers.toggled,
    showHistoricalData: widget.config.showHistoricalData.toggled,
    rounding: widget.config.rounding,
    stringValueSize: widget.config.stringValueSize.value,
    grouping: widget.config.grouping.value,
    showArea: widget.config.showArea,
    showThresholdMarkers: widget.config.showThresholdMarkers,
    comparePoints: widget.config.comparePoints,
    comparisonType: widget.config.comparisonType,
    comparisonCustomValue: widget.config.comparisonCustomValue,
    comparisonMethod: widget.config.comparisonMethod,
    comparisonOnlyShowInLabel: widget.config.comparisonOnlyShowInLabel,
    sorting: widget.config.sorting.value,
    groupBySorting: widget.config.groupBySorting,
    valueLimit: widget.config.valueLimit,
    barGap: widget.config.barGap,
    timeIncrement: widget.config.timeIncrement.value,
    dailySnapshotTimeCutoff: widget.config.dailySnapshotTimeCutoff,
    timezone: widget.config.timezone,
    showWidgetTitle: widget.config.showWidgetTitle,
    modalDataSortColumn: widget.config.modalDataSortColumn,
    modalDataSortAscending: widget.config.modalDataSortAscending,
    modalDataLimit: widget.config.modalDataLimit,
    dynamicStringColumn: widget.dynamicStringColumn,
    cumulativeXAxis: widget.config.cumulativeXAxis,
    xAxisBaseline: widget.config.xAxisBaseline,
    xAxisLabelRotation: widget.config.xAxisLabelRotation,
    pieRadius: widget.config.pieRadius,
    pieInnerRadius: widget.config.pieInnerRadius,
    maxGaugeValue: widget.config.maxGaugeValue,
    minGaugeValue: widget.config.minGaugeValue,
    yAxisLogScale: widget.config.yAxisLogScale,
    logScaleBase: widget.config.logScaleBase,
    hideZeroLabels: widget.config.hideZeroLabels,
    showStackTotal: widget.config.showStackTotal,
    pinnedColumns: widget.config.pinnedColumns,
    matrixMissingValue: widget.config.matrixMissingValue,
    matrixIndexLabel: widget.config.matrixIndexLabel,
    drilldownDateFormat: widget.config.drilldownDateFormat,
    useCalculationThresholds: widget.config.useCalculationThresholds,
    transposeTableData: widget.config.transposeTableData,
    transposeIncludeHeader: widget.config.transposeIncludeHeader,
    transposeHeaderColumnName: widget.config.transposeHeaderColumnName,
    transposeColumn: widget.config.transposeColumn,
    transposePinHeaderColumn: widget.config.transposePinHeaderColumn,
    pivotTableData: widget.config.pivotTableData,
    pivotColumns: widget.config.pivotColumns,
    pivotValues: widget.config.pivotValues,
    pivotIndex: widget.config.pivotIndex,
    pivotAggFunc: widget.config.pivotAggFunc,
    pivotPinIndexColumns: widget.config.pivotPinIndexColumns,
    thresholdsByCalculation: widget.thresholdsByCalculation,
    columnsWithDollarPrefix: widget.config.columnsWithDollarPrefix,
    columnsWithPercentSuffix: widget.config.columnsWithPercentSuffix,
    fillEmptyPoints: widget.config.fillEmptyPoints,
    horizontalChart: widget.config.horizontalChart,
    compareWithPreviousPeriod: widget.config.compareWithPreviousPeriod,
    useXAxisInDashboardDateFilter: widget.config.useXAxisInDashboardDateFilter,
    fitGridToWidgetWidth: widget.config.fitGridToWidgetWidth,
    id: widget.id,
    groupId: widget.groupId,
    configId: widget.config.id,
    gridsterItem: widget.gridsterItem,
    calculationOrder: widget.config.calculationOrder,
  };
  return { ...formValues };
};
export const getFormValuesFromStorableCalc = (
  calculation: ICalculationV2,
  dataStreams: IDataStreamMap,
  colors: IWidgetColors,
): ICalcFormValues => {
  const dataStream = dataStreams[calculation.dataStreamId];
  const columns = dataStream.columnDetails;
  const selectedColumns: string[] = calculation.includedColumns;
  return {
    dataStream: dataStreams[calculation.dataStreamId],
    name: calculation.name,
    xAxis: calculation.xAxis,
    xAxis2: calculation.xAxis2,
    delimiter2: calculation.delimiter2,
    // groupingColumns: calculation.gr ? [calculation.gr] : [],
    groupBy: calculation.groupBy,
    simpleFilter: calculation.filter?.simpleFilter,
    pandasFilter: calculation.filter?.pandasFilter,
    filterType: calculation.useFilter
      ? calculation.filter.advancedFilter
        ? 'pandas'
        : 'simple'
      : 'none',
    percentFilter: calculation.percentFilter,
    computation: getComputationConstants()[calculation.calculationOperation],
    computationColumn: calculation.calculationColumn,
    timeWindow: calculation.trendFreq,
    useDefaultColor: colors[calculation.id].useDefault,
    color: colors[calculation.id].color,
    delimiter: calculation.delimiterEnabled ? calculation.delimiter : null,
    selectedColumns,
    excludeColumnsMode: calculation.excludeColumnsMode || false,
    columns,
    columnOptions: columns?.map((col) => col.name),
    columnAliasMap: columns?.reduce(
      (acc, col) => ({ ...acc, [col.name]: col.alias }),
      {},
    ),
    id: calculation.id,
    filteredColumns: columns,
    useCustomCalculationColumn: calculation.useCustomCalculationColumn,
    customCalculationColumn:
      calculation.customCalculationColumn ||
      JSON.parse(JSON.stringify(defaultColumnOperation)),
    dateFilterColumn: calculation.dateFilterColumn,
  };
};

export const dailySnapshotTimeCutoffOptions = [
  { value: '01:02:30', label: '1 AM' },
  { value: '02:02:30', label: '2 AM' },
  { value: '03:02:30', label: '3 AM' },
  { value: '04:02:30', label: '4 AM' },
  { value: '05:02:30', label: '5 AM' },
  { value: '06:02:30', label: '6 AM' },
  { value: '07:02:30', label: '7 AM' },
  { value: '08:02:30', label: '8 AM' },
  { value: '09:02:30', label: '9 AM' },
  { value: '10:02:30', label: '10 AM' },
  { value: '11:02:30', label: '11 AM' },
  { value: '12:02:30', label: '12 PM' },
  { value: '13:02:30', label: '1 PM' },
  { value: '14:02:30', label: '2 PM' },
  { value: '15:02:30', label: '3 PM' },
  { value: '16:02:30', label: '4 PM' },
  { value: '17:02:30', label: '5 PM' },
  { value: '18:02:30', label: '6 PM' },
  { value: '19:02:30', label: '7 PM' },
  { value: '20:02:30', label: '8 PM' },
  { value: '21:02:30', label: '9 PM' },
  { value: '22:02:30', label: '10 PM' },
  { value: '23:02:30', label: '11 PM' },
  { value: '23:59:59', label: 'End of Day' },
];
