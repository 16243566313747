<ng-container *ngIf="widget$ | async">
  <div class="export-button-container">
    <button
      nbTooltip="Export table to a CSV file"
      class="export-button"
      nbButton
      [nbSpinner]="isLoading$ | async"
      status="basic"
      ghost
      size="small"
      (click)="exportTable()"
    >
      <nb-icon icon="cloud-download-outline"></nb-icon> Export
    </button>
  </div>
  <div
    class="component-container"
    *ngIf="(widget$ | async).hasColumnInfo; else waitingForNewDatasetData"
  >
    <ng-container *ngIf="tableInputData$ | async">
      <div class="table-container">
        <ag-grid-angular
          style="width: 100%; height: 100%"
          class="ag-theme-alpine"
          [rowData]="(tableInputData$ | async).data"
          [columnDefs]="(tableInputData$ | async).agColumnDefs"
          (gridReady)="onGridReady($event)"
          [gridOptions]="gridOptions"
          [suppressFieldDotNotation]="true"
        >
        </ag-grid-angular>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #waitingForNewDatasetData>
  <div class="no-column-info">
    <h6>Waiting for Dataset Sync</h6>
  </div>
</ng-template>
