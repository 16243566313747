<ng-container
  *ngIf="(widget$ | async)?.hasSomeData || noDataWidgetType; else noData"
>
  <div
    *ngIf="showChart"
    echarts
    [options]="chartStarterOptions"
    [merge]="chartOptions$ | async"
    (chartClick)="onChartClick($event)"
    (chartInit)="onChartInit($event)"
    class="chart-container"
    [ngStyle]="{
      width: showChart ? '100%' : '0',
      height: showChart ? '100%' : '0',
    }"
  ></div>
  <ng-container *ngIf="widget$ | async as widget">
    <div
      *ngIf="
        !showChart ||
        (widget.config.overlayCurrentNumber && widget.widgetType === 'line')
      "
      (resplendentObserveResize)="onNumberResize($event, widget)"
      class="no-chart-data"
      [ngClass]="{
        'no-chart-data-overlay': widget.config.overlayCurrentNumber,
        'no-chart-data-no-overlay': !widget.config.overlayCurrentNumber,
      }"
      #componentContainer
      (click)="numberClick()"
    >
      <ng-container
        *ngIf="
          widget.widgetType === 'number' ||
          widget.widgetType === 'staticString' ||
          widget.widgetType === 'dynamicString' ||
          (widget.widgetType === 'line' && widget.config.overlayCurrentNumber)
        "
      >
        <ng-container *ngIf="autoResizeString$ | async as autoResizeString">
          <div
            class="widget-number-container"
            [style.fontSize.px]="autoResizeString.fontSize"
            [style.color]="autoResizeString.color"
          >
            <div class="number-and-trend">
              <div class="widget-number">
                {{ autoResizeString.text }}
              </div>
              @if (numberDirectionInformation$ | async; as nDI) {
              <div class="previous-value-change">
                <span
                  [ngClass]="{
                    'positive-change': nDI.trend === 'up',
                    'negative-change': nDI.trend === 'down',
                    'neutral-change': nDI.trend === 'neutral'
                  }"
                >
                  {{ nDI.percentChange }}
                </span>
              </div>
              }
            </div>
            @if (numberDirectionInformation$ | async; as nDI) {
            <div class="previous-value">
              {{ nDI.previousValue }} previous period
            </div>
            }
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="widget.widgetType === 'iFrame'">
        <iframe
          [src]="widget.iFrameUrl | trustUrlString"
          class="iframe"
          height="100%"
          width="100%"
          allow="autoplay"
          ><p>iframes are not supported by your browser.</p></iframe
        >
        <!-- This div covers up the iframe during editing so we can catch mouse events for dragging/resizing -->
        <div *ngIf="isInEditor" class="mouse-catcher"></div>
      </ng-container>
      <ng-container *ngIf="widget.widgetType === 'image'">
        <img *ngIf="widget.imageUrl" class="image" [src]="widget.imageUrl" />
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #noData>
  <div class="no-data">
    <div class="no-data-text">
      <span>No data available</span>
    </div>
  </div>
</ng-template>

<ng-template #positivePeriod>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height="24px">
    <path
      d="M8 10H23V22C23 23.6569 21.6569 25 20 25H11C9.34315 25 8 23.6569 8 22V10Z"
      fill="#2CE69B"
    />
    <path
      d="M14.7909 0.713163C15.1819 0.319952 15.8181 0.319952 16.2091 0.713163L27.228 11.7949C27.8552 12.4257 27.4084 13.5 26.5189 13.5H4.48114C3.5916 13.5 3.14482 12.4257 3.77202 11.7949L14.7909 0.713163Z"
      fill="#2CE69B"
    />
  </svg>
</ng-template>
