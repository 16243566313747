import { Component, Input, OnInit } from '@angular/core';
import { ITableModalDataV2 } from '@interfaces';
import { Store } from '@ngxs/store';
import { renderModalCellV2 } from '@pages/dash-v2/helpers';
import { BaseComponent } from '@root/core-components/base-component';
import { IAppStateModel } from '@root/state/app.model';
import { EventQueueService } from '@services';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GroupCellRendererParams,
} from 'ag-grid-community';
import { filter, first, map, Observable, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'resplendent-widget-table',
  templateUrl: './widget-table.component.html',
  styleUrls: ['./widget-table.component.scss'],
})
export class WidgetTableComponent extends BaseComponent implements OnInit {
  @Input() widgetId: string;
  @Input() isInEditor: boolean;

  widget$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
  );

  tableInputData$: Observable<ITableModalDataV2 | undefined>;
  public gridApi: GridApi;
  public gridOptions: GridOptions = {
    enableCellTextSelection: true,
    ensureDomOrder: true,
    autoSizeStrategy: {
      type: 'fitCellContents',
    },
  };
  private scrollPosition = { top: 0, left: 0, rowIndex: 0 };

  constructor(private store: Store, private eventQueue: EventQueueService) {
    super();
  }

  ngOnInit(): void {
    if (!this.widgetId) throw new Error('Need widget id to display table');
    this.setTableObservables();
  }

  private setTableObservables() {
    this.tableInputData$ = this.widget$.pipe(
      takeUntil(this.isDestroyed$),
      filter((widget) => !!widget && widget.hasColumnInfo),
      map((widget) => {
        this.gridOptions.autoSizeStrategy = {
          type: widget.config.fitGridToWidgetWidth
            ? 'fitGridWidth'
            : 'fitCellContents',
        };
        const calculations = widget.calculations;
        const calcList = Object.values(calculations);
        const calcCount = calcList.length;
        if (calcCount > 1 && widget.widgetType === 'table') {
          throw new Error(
            `Table graph can be displayed with and only with one subscribed calculation, instead there were ${calcCount} calculations`,
          );
        }
        const calculation = calcList[0];
        if (!calculation) return;

        let subtitle = calculation.name;
        const calcData = calculation.getActiveData();
        const tableInputData: ITableModalDataV2 = {
          dataTitles: ['No data for this widget'],
          data: [],
          delimiter: null,
          agColumnDefs: [],
          subtitle,
          columnNameMap: calcData?.column_labels,
          color: '#696969',
          stack: 420,
          calcId: calculation.id,
          xAxisDataType: 'string',
        };
        if (!calcData) return tableInputData;
        const pinnedColumns = widget.config.pinnedColumns
          ? [...widget.config.pinnedColumns]
          : [];
        let calcModalData = calcData.modal_data;
        if (!calcModalData || calcModalData.length === 0) return tableInputData;
        let dataTitles = calculation.getSelectedColumns();
        if (!dataTitles) return tableInputData;
        tableInputData.dataTitles = dataTitles;
        if (calculation.includedColumns && !calculation.excludeColumnsMode) {
        }
        let currentDataColumns = Object.keys(calcData.modal_data[0]);
        let transposedColumns = null;
        if (
          widget.config.transposeTableData &&
          widget.config.transposeIncludeHeader
        ) {
          const headerCol = widget.config.transposeHeaderColumnName;
          transposedColumns = {};
          for (const row of calcModalData) {
            for (const col of currentDataColumns) {
              if (!transposedColumns[col]) {
                transposedColumns[col] = {};
              }
              transposedColumns[col][row[headerCol]] = row[col];
            }
          }
          calcModalData = calcModalData.filter(
            (row) => !row[headerCol]?.includes('__link__'),
          );
          calcModalData.sort(
            (a, b) =>
              dataTitles.indexOf(a[headerCol]) -
              dataTitles.indexOf(b[headerCol]),
          );
        }
        if (widget.config.transposeTableData || widget.config.pivotTableData) {
          dataTitles = currentDataColumns;
        }
        if (
          widget.config.pivotTableData &&
          widget.config.pivotPinIndexColumns
        ) {
          pinnedColumns.push(...widget.config.pivotIndex);
        }
        if (
          widget.config.transposeTableData &&
          widget.config.transposePinHeaderColumn
        ) {
          pinnedColumns.push(widget.config.transposeHeaderColumnName);
        }
        tableInputData.data = calcModalData;
        let agColumnDefs: ColDef[] = [];
        dataTitles.forEach((col) => {
          if (currentDataColumns.includes(col)) {
            agColumnDefs.push({
              cellRenderer: (params: GroupCellRendererParams) => {
                return renderModalCellV2(
                  params.data,
                  params.colDef.field,
                  calculation,
                  widget,
                  transposedColumns,
                );
              },
              headerName: calcData.column_labels[col],
              field: col,
              sortable: true,
            });
            if (pinnedColumns.includes(col)) {
              agColumnDefs[agColumnDefs.length - 1].pinned = 'left';
            }
          }
        });
        tableInputData.agColumnDefs = agColumnDefs;
        tableInputData.links = calcData.column_links;
        const rowIndex = this.scrollPosition.rowIndex;
        if (this.gridApi) {
          setTimeout(() => {
            this.gridApi.ensureIndexVisible(rowIndex, 'top');
          }, 0);
        }
        return tableInputData;
      }),
    );
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    params.api.addEventListener(
      'bodyScroll',
      this.saveScrollPosition.bind(this),
    );
  }

  exportTable() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    );
    if (!widget) return;
    const selectedData = widget.calcArray[0].getActiveData();
    if (!selectedData) return;
    const isFiveHundredOrMore = selectedData.modal_data.length >= 500;

    if (isFiveHundredOrMore) {
      this.startLoading();
      this.eventQueue
        .on('GET_FULL_MODAL_DATA')
        .pipe(first(), takeUntil(this.isDestroyed$))
        .subscribe(() => this.stopLoading());
      this.eventQueue.dispatch('DOWNLOAD_BIG_BOI_TABLE_V2', {
        calcId: selectedData.calculation_uuid,
        label: widget.title,
      });
      this.eventQueue.dispatch('SHOW_TOAST', {
        duration: 3000,
        title: 'Preparing your data export...',
        message: 'This can take a few minutes if your dataset is large',
        status: 'info',
      });
    } else {
      this.gridApi.exportDataAsCsv({ fileName: `${widget.title}.csv` });
    }
  }

  private saveScrollPosition = () => {
    const gridApi = this.gridApi;
    if (!gridApi) return;
    const top = gridApi.getVerticalPixelRange().top;
    const left = gridApi.getHorizontalPixelRange().left;
    this.scrollPosition = {
      top: top,
      left: left,
      rowIndex: Math.round(top / gridApi.getDisplayedRowAtIndex(0).rowHeight),
    };
  };
}
