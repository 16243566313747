import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  abbreviateNumber,
  containsDowOrMonth,
  getContrastColor,
  sortByDowOrMonth,
} from '@helpers';
import { ITableModalDataV2, IWidgetClickTargetV2 } from '@interfaces';
import { Store } from '@ngxs/store';
import { BaseComponent } from '@root/core-components/base-component';
import { TimeDelta } from '@root/custom_scripts/TimeDeltaStuff';
import { IAppStateModel } from '@root/state/app.model';
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  GroupCellRendererParams,
} from 'ag-grid-community';
import { filter, map, Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'resplendent-widget-matrix',
  templateUrl: './widget-matrix.component.html',
  styleUrls: ['./widget-matrix.component.scss'],
})
export class WidgetMatrixComponent extends BaseComponent implements OnInit {
  @Input() widgetId: string;
  @Input() isInEditor: boolean;
  @Output() emitWidgetClick = new EventEmitter<IWidgetClickTargetV2>();
  clickTimeout = null;
  widgetClickBuffer: IWidgetClickTargetV2[] = [];
  widget$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
  );
  tableInputData$: Observable<ITableModalDataV2 | undefined>;
  public gridApi: GridApi;
  private scrollPosition = { top: 0, left: 0, rowIndex: 0 };

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    if (!this.widgetId) throw new Error('Need widget id to display matrix');
    this.setTableObservables();
  }

  private setTableObservables() {
    this.tableInputData$ = this.widget$.pipe(
      takeUntil(this.isDestroyed$),
      filter((widget) => !!widget),
      map((widget) => {
        const ezConfigMap = widget.getEzConfigMap();
        const calcList = widget.calcArray;
        const calculation = calcList[0];
        if (!calculation) return;
        const config = widget.config;
        let subtitle = calculation.name;
        const calcData = calculation.getActiveData();
        const tableInputData: ITableModalDataV2 = {
          dataTitles: ['No data for this widget'],
          data: [],
          delimiter: null,
          agColumnDefs: [],
          subtitle,
          columnNameMap: calcData?.column_labels,
          color: '#696969',
          stack: 420,
          calcId: calculation.id,
          xAxisDataType: 'string',
        };
        if (!calcData) return tableInputData;
        const pinnedColumns = config.pinnedColumns ?? [];
        let agColumnDefs: ColDef[] = [
          {
            field: '____name',
            headerName: config.matrixIndexLabel || 'name',
            sortable: false,
            autoHeight: true,
            wrapText: true,
            wrapHeaderText: true,

            // cellStyle: { 'border-right-color': '#e2e2e2' },
          },
        ];
        if (pinnedColumns.includes('____name')) {
          agColumnDefs[0].pinned = 'left';
        }

        const baseline = config.xAxisBaseline
          ? calcData.extras.x_axis_baseline
          : null;
        let row_idx = {};
        let data = [];
        if (baseline) {
          baseline.forEach((category) => {
            row_idx[category] = data.length;
            data.push({ ____name: category });
          });
        }
        calcList.forEach((calculation) => {
          let cData = calculation.getActiveData();
          if (!cData) return;
          cData.x.forEach((row, idx) => {
            const category = row;
            if (baseline && !baseline.includes(category)) return;
            if (!(category in row_idx)) {
              row_idx[category] = data.length;
              data.push({ ____name: category });
            }
            let cellData: number | string = cData.y[idx].values[0];
            if (cData.type === 'timedelta') {
              cellData = new TimeDelta(cellData).toReadable();
            }
            data[row_idx[category]][calculation.id] = cellData;
          });

          const roundTo = config.rounding ?? 0;
          const abbreviate = ezConfigMap.abbreviateNumbers;
          const addPercentSuffix = ezConfigMap.percentSuffix;
          const addDollarPrefix = ezConfigMap.dollarPrefix;
          agColumnDefs.push({
            cellRenderer: (params: GroupCellRendererParams) => {
              let cellValue = params.data[params.colDef.field];
              if (!cellValue) {
                cellValue = widget.config.matrixMissingValue;
                const numericData = Number(cellValue);
                cellValue = !Number.isNaN(numericData)
                  ? numericData
                  : cellValue;
              }

              let threshColor = '';
              if (typeof cellValue === 'number') {
                if (widget.config.colorType.value == 'thresholds') {
                  let thresholds;
                  if (config.useCalculationThresholds) {
                    thresholds = widget.thresholdsByCalculation[calculation.id];
                  } else {
                    thresholds = widget.thresholds;
                  }
                  if (thresholds) {
                    threshColor = thresholds.bottom;
                    for (let threshold of thresholds.custom) {
                      if (cellValue > threshold.value) {
                        threshColor = threshold.color;
                      }
                    }
                  }
                }
                cellValue = cellValue.toFixed(roundTo);
                abbreviate && (cellValue = abbreviateNumber(cellValue));
              }
              addPercentSuffix && (cellValue += '%');
              if (addDollarPrefix) {
                // Dollar prefix is a little more complicated, don't wanna see $-100 instead of -$100
                const isNegative = cellValue.startsWith('-');
                const prefix = isNegative ? '-$' : '$';
                cellValue = prefix + cellValue.replace('-', '');
              }
              let textColor = '';
              if (
                widget.config.colorType.value == 'thresholds' &&
                threshColor
              ) {
                if (!widget.config.showThresholdMarkers) {
                  textColor = `style="color: ${threshColor}"`;
                } else {
                  textColor = `style="background: ${threshColor}; color: ${getContrastColor(
                    threshColor,
                  )};"`;
                }
              }
              return `<div ${textColor}>${cellValue}</div>`;
            },
            headerName: calculation.name,
            field: calculation.id,
            sortable: true,
            autoHeight: true,
            wrapText: true,
            wrapHeaderText: true,
          });
          if (pinnedColumns.includes(calculation.id)) {
            agColumnDefs[agColumnDefs.length - 1].pinned = 'left';
          }
        });
        tableInputData.agColumnDefs = agColumnDefs;
        const sortColOpts = [
          ...widget.calcArray.map((calc) => calc.id),
          '____name',
        ];
        const selectedSortColumn = widget.config.modalDataSortColumn;
        const defaultSortColumn = '____name';
        const sortColumnIsValid = sortColOpts.includes(selectedSortColumn);
        const sortColumn = sortColumnIsValid
          ? selectedSortColumn
          : defaultSortColumn;
        const sortDirection = widget.config.sorting.value;
        switch (sortDirection) {
          case 'ascending':
            // Sort by y value from smallest to largest
            data = data.sort((a, b) => a[sortColumn] - b[sortColumn]);
            break;
          case 'descending':
            // Sort by y value from largest to smallest
            data = data.sort((a, b) => b[sortColumn] - a[sortColumn]);
            break;
          case 'dowMonthAsc':
          case 'dowMonthDesc':
            // Sort the x axis by day of the week, then by month, then by year, ascending.
            // The data can come is as a string like Tuesday or 2/1/2021, so we need to convert it to a Date object
            if (containsDowOrMonth(data.map((item) => item[sortColumn]))) {
              data = sortByDowOrMonth(data, sortDirection, sortColumn);
            } else {
              try {
                data = data.sort((a, b) => {
                  const dateA = new Date(a[sortColumn]);
                  const dateB = new Date(b[sortColumn]);
                  if (isNaN(dateA.getTime())) {
                    return 1; // Move null values to the end
                  }
                  if (isNaN(dateB.getTime())) {
                    return -1; // Move null values to the end
                  }
                  return dateA.getTime() - dateB.getTime();
                });
              } catch (e) {
                data.sort((a, b) => {
                  if (a[sortColumn] < b[sortColumn]) return -1;
                  else if (a[sortColumn] > b[sortColumn]) return 1;
                  else return 0;
                });
              }
              if (sortDirection === 'dowMonthDesc') {
                data.reverse();
              }
            }
            break;
          default:
            // If no sorting is selected, sort by x value
            data = data.sort((a, b) => {
              if (a[sortColumn] === null) {
                return 1;
              } else if (b[sortColumn] === null) {
                return -1;
              }
              // If the values are strings, sort them as strings
              if (typeof a[sortColumn] === 'string') {
                return a[sortColumn].localeCompare(b[sortColumn]);
              }

              if (a[sortColumn] < b[sortColumn]) return -1;
              else if (a[sortColumn] > b[sortColumn]) return 1;
              else return 0;
            });
            if (sortDirection === 'reverse') {
              data.reverse();
            }
        }
        if (widget.config.valueLimit) {
          data = data.slice(0, widget.config.valueLimit);
        }
        // sort data by name
        tableInputData.data = data;

        const rowIndex = this.scrollPosition.rowIndex;
        if (this.gridApi) {
          setTimeout(() => {
            this.gridApi.ensureIndexVisible(rowIndex, 'top');
          }, 0);
        }

        return tableInputData;
      }),
    );
  }
  exportTable() {
    if (!this.gridApi) return;
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    );
    this.gridApi.exportDataAsCsv({ fileName: widget.title });
  }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.sizeColumns();
    params.api.addEventListener(
      'bodyScroll',
      this.saveScrollPosition.bind(this),
    );
  }
  sizeColumns() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    );
    if (widget.config.pinnedColumns?.length > 0) {
      this.gridApi?.autoSizeAllColumns();
    } else {
      this.gridApi?.sizeColumnsToFit();
    }
  }
  onCellClicked(event: CellClickedEvent) {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    );
    const clickTarget: IWidgetClickTargetV2 = {
      widgetId: this.widgetId,
      xValue: event.data?.____name,
    };

    const calc_id = event.colDef.field;
    if (calc_id === '____name') {
      this.widgetClickBuffer.push(clickTarget);
      return;
    }
    clickTarget.calcTarget = {
      label: widget.calculations[calc_id].name,
      frontEndCalcId: calc_id,
    };
    this.widgetClickBuffer.push(clickTarget);
    if (this.clickTimeout) return;
    this.clickTimeout = setTimeout(() => this.processClickBuffer(), 100);
  }
  onColumnHeaderClicked(event) {
    this.widgetClickBuffer = [];
  }
  parentDivClicked() {
    this.widgetClickBuffer.push({ widgetId: this.widgetId });
    if (this.clickTimeout) return;
    this.clickTimeout = setTimeout(() => this.processClickBuffer(), 100);
  }
  processClickBuffer() {
    this.widgetClickBuffer.sort((a, b) =>
      !a.calcTarget > !b.calcTarget ? 1 : -1,
    );
    this.emitWidgetClick.emit(this.widgetClickBuffer[0]);
    this.widgetClickBuffer = [];
    this.clickTimeout = null;
  }

  private saveScrollPosition = () => {
    const gridApi = this.gridApi;
    if (!gridApi) return;
    const top = gridApi.getVerticalPixelRange().top;
    const left = gridApi.getHorizontalPixelRange().left;

    this.scrollPosition = {
      top: top,
      left: left,
      rowIndex: Math.round(top / gridApi.getDisplayedRowAtIndex(0).rowHeight),
    };
  };
}
